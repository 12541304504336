import { useToast } from "vue-toastification";
import api from "@/axiosConfig";
import { format } from "date-fns";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";

const toast = useToast();

const state = {
  selectedTrainingDate: null,
  selectedTrainingTimeData: null,

  isHorseDontHaveTrainings: false,
  selectedDayTrainings: [],
  currentTrainingMetadata: null,
  selectedDayTrainingsMetadata: {},
  reports: null,
  trackingPaused: true,
  selectedChartsList: [],
  trainingDates: [],
  horseLameness: null,
  horseLamenessSettings: null,

  compareTrainingFrom: null,
  compareTrainingTo: null,

  selectedCompareTrainingFrom: null,
  selectedCompareTrainingTo: null,

  reportsCompareTrainingFrom: null,
  reportsCompareTrainingTo: null,
};

const getters = {
  selectedTrainingTimeData: (state) => state.selectedTrainingTimeData,
  selectedTrainingDate: (state) => state.selectedTrainingDate,

  isHorseDontHaveTrainings: (state) => state.isHorseDontHaveTrainings,
  selectedDayTrainings: (state) => state.selectedDayTrainings,
  currentTrainingMetadata: (state) => state.currentTrainingMetadata,
  selectedDayTrainingsMetadata: (state) => state.selectedDayTrainingsMetadata,
  reports: (state) => state.reports,
  trackingPaused: (state) => state.trackingPaused,
  chartsData: (state) => state.chartsData,
  selectedChartsList: (state) => state.selectedChartsList,
  trainingDates: (state) => state.trainingDates,
  horseLameness: (state) => state.horseLameness,
  horseLamenessSettings: (state) => state.horseLamenessSettings,

  compareTrainingFrom: (state) => state.compareTrainingFrom,
  compareTrainingTo: (state) => state.compareTrainingTo,

  selectedCompareTrainingFrom: (state) => state.selectedCompareTrainingFrom,
  selectedCompareTrainingTo: (state) => state.selectedCompareTrainingTo,

  reportsCompareTrainingFrom: (state) => state.reportsCompareTrainingFrom,
  reportsCompareTrainingTo: (state) => state.reportsCompareTrainingTo,
};

const mutations = {
  SET_HORSE_DONT_HAVE_TRAININGS(state, data) {
    state.isHorseDontHaveTrainings = data;
  },
  SET_SELECTED_TRAINING_DATE(state, data) {
    state.selectedTrainingDate = data;
  },
  SET_SELECTED_TRAINING_TIME_DATA(state, data) {
    state.selectedTrainingTimeData = data;
  },

  SET_TRACKING_PAUSED(state, data) {
    state.trackingPaused = data;
  },
  SET_CURRENT_DAY_TRAININGS(state, data) {
    state.selectedDayTrainings = data;
  },
  SET_CURRENT_DAY_TRAININGS_METADATA(state, data) {
    state.selectedDayTrainingsMetadata = data;
  },
  SET_CURRENT_TRAINING_METADATA(state, data) {
    state.currentTrainingMetadata = data;
  },
  SET_REPORTS_DATA(state, data) {
    state.reports = data;
  },
  UPDATE_SELECTED_CHARTS_LIST(state, data) {
    state.selectedChartsList = data;
  },
  SET_TRAININGS_DATES(state, data) {
    state.trainingDates = data;
  },
  SET_HORSE_LAMENESS(state, data) {
    state.horseLameness = data;
  },
  SET_COMPARE_TRAINING(state, data) {
    if (data.type === "compareTrainingFrom")
      state.compareTrainingFrom = data.data;
    else state.compareTrainingTo = data.data;
  },
  SET_SELECTED_COMPARE_TRAINING(state, data) {
    data.type === "compareFrom"
      ? (state.selectedCompareTrainingFrom = data.data)
      : (state.selectedCompareTrainingTo = data.data);
  },
  SET_REPORTS_COMPARE_TRAINING(state, data) {
    state[data.type] = data.data;
  },
  SET_HORSE_LAMENESS_SETTINGS(state, data) {
    state.horseLamenessSettings = data;
  },
};

const actions = {
  async getTrainingsDates({ commit }, id) {
    return api.get(`horses/${id}/trainings-calendar`).then((res) => {
      commit("SET_TRAININGS_DATES", res.data.dates);
    });
  },
  async getSelectedDayTrainingsData({ commit }, params) {
    let trainingMetadata = {};
    trainingMetadata.date = params.fromDate;

    // TODO add logic for page changing, not size
    return api
      .get(`horses/${params.id}/trainings`, {
        params: {
          "from-date": params.fromDate,
          "to-date": params.toDate,
          size: 100,
        },
      })
      .then((res) => {
        const trainings = res.data.trainings.content;
        const mappedTrainings = [];
        trainings.map((i) => {
          mappedTrainings.push({
            startTime: format(new Date(i.startTime), "HH:mm"),
            endTime: format(new Date(i.endTime), "HH:mm"),
            trainingId: i.results[0].trainingId,
            results: i.results.map((res) => {
              trainingMetadata[res.resultType] = res.fileName;
              return {
                created: i.startTime,
                trainingId: i.results[0].trainingId,
                type: res.resultType,
                id: res.id,
                rowResult: res.rowResult,
              };
            }),
          });
        });
        if (params.type) {
          // set compare trainings
          const data = {};
          data.mappedTrainings = mappedTrainings;
          data.trainingMetadata = trainingMetadata;
          data.date = params.fromDate;

          commit("SET_COMPARE_TRAINING", { data, type: params.type });
        } else {
          // set current training
          commit(
            "SET_CURRENT_DAY_TRAININGS",
            mappedTrainings.sort((a, b) => {
              const dateA = new Date(a.results[0].created);
              const dateB = new Date(b.results[0].created);
              return dateA - dateB;
            })
          );
          commit("SET_CURRENT_DAY_TRAININGS_METADATA", trainingMetadata);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async getCurrentTrainingData({ commit }, id) {
    try {
      const res = await api.get(`reports/training/${id}`);

      // Handle API-specific error checking
      if (res.data?.success === false) {
        showErrorAndGoToHomePage(res.data.error.error_message);
        return;
      }

      // Commit data to the store if everything is correct
      commit("SET_CURRENT_TRAINING_METADATA", res.data.training);
    } catch (err) {
      showErrorAndGoToHomePage(err.message);
    }
  },

  async getTrainingReports({ commit }, params) {
    let result = {};
    await Promise.all(
      params.reports.map((i) =>
        api
          .get(`reports/training/${params.id}/report/${i.id}/json`)
          .then((res) => {
            const keys = Object.keys(res.data);
            keys.map(
              (i, index) => (result[i] = Object.values(res.data)[index])
            );
          })
      )
    );
    if (params.type) {
      commit("SET_REPORTS_COMPARE_TRAINING", {
        data: result,
        type: params.type,
      });
    } else {
      commit("SET_REPORTS_DATA", result);
    }

    if (
      !Object.keys(result).includes("TRACKING") ||
      !Object.keys(result).includes("SUMMARY")
    ) {
      toast.error("ML tracking data not found");
    }
  },
  async getHorseLameness({ commit }, id) {
    return api.get(`reports/horse/${id}/signatures`).then((res) => {
      commit("SET_HORSE_LAMENESS", res.data.reports);
    });
  },
  async getSettings({ commit }) {
    return api.get(`settings`).then((res) => {
      commit("SET_HORSE_LAMENESS_SETTINGS", res.data);
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
