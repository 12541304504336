<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope"
  >
    <div class="self-start ml-6" v-if="!isTabSelected">
      <span class="text-xl font-semibold text-[gray]">
        {{ $t("comparison.No charts selected") }} <br />
        {{ $t("comparison.Select tab") }}
      </span>
    </div>
    <div
      class="w-full mx-auto mb-4 px-8 py-4 pb-4 flex flex-col justify-between flex-wrap rounded-lg relative"
      :class="isTabSelected ? 'bg-white shadow' : ''"
    >
      <div class="block w-full mb-4">
        <nav
          class="isolate flex divide-x divide-gray-200 rounded-lg shadow overflow-x-auto"
          aria-label="Tabs"
        >
          <button
            v-for="(tab, tabIdx) in tabs"
            :key="tab.name"
            :class="[
              tabIdx === 0 ? 'rounded-l-lg' : '',
              tab.isEnabled ? '' : 'hidden',
              tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
              'group relative flex-1 whitespace-nowrap bg-[#FAF0E6] py-2 px-4 text-center hover:bg-gray-300 focus:z-10 text-[#5A5A5F] text-[18px] font-semibold flex items-center justify-center',
            ]"
            :aria-current="tab.current ? 'page' : undefined"
            @click="handleSwitchTab(tabIdx)"
          >
            <span>{{ $t(tab.name) }}</span>
            <span
              aria-hidden="true"
              :class="[
                tab.current ? 'bg-[#9A8053]' : 'bg-indigo-200',
                'absolute inset-x-0 bottom-0 h-0.5',
              ]"
            />
          </button>
        </nav>
      </div>

      <div
        class="w-full"
        v-show="currentTabIdx === 0 && tabs[currentTabIdx].isEnabled"
      >
        <MultipleChartComponent
          :mappedData="commonsData"
          :multicharts="multicharts"
        />
      </div>

      <div
        class="w-full"
        v-show="currentTabIdx === 1 && tabs[currentTabIdx].isEnabled"
      >
        <SkewnessComparisonComponent />
      </div>
      <div
        class="w-full"
        v-show="currentTabIdx === 2 && tabs[currentTabIdx].isEnabled"
      >
        <CumulativeEnergyComparison />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MultipleChartComponent from "@/components/training/comparison/multiChart/MultipleChartComponent.vue";
import SkewnessComparisonComponent from "@/components/training/comparison/SkewnessComparisonComponent.vue";
import CumulativeEnergyComparison from "@/components/training/comparison/CumulativeEnergyComparison.vue";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";
import {
  trackingModeKeys,
  trackingModes,
} from "@/components/training/constants";

export default {
  name: "TrainingsComparisonComponent",
  components: {
    MultipleChartComponent,
    SkewnessComparisonComponent,
    CumulativeEnergyComparison,
  },
  data() {
    return {
      trackingModes,
      trainings: {},
      commonsData: [],
      multicharts: [],
      currentTabIdx: 0,
    };
  },
  computed: {
    ...mapGetters([
      "trainingDates",
      "loading",
      "horsesList",
      "horseTrackingCard",
      "currentTrainingMetadata",
      "selectedCompareTrainingFrom",
      "selectedCompareTrainingTo",
      "reportsCompareTrainingFrom",
      "reportsCompareTrainingTo",
      "selectedChartsList",
      "selectedDayTrainings",
      "trackingMode",
      "GPSDatafiles",
    ]),
    tabs() {
      return [
        {
          name: "comparison.Multichart",
          href: "#",
          current: this.currentTabIdx === 0,
          isEnabled: this.selectedChartsList.includes("Comparison Multichart"),
        },
        {
          name: "comparison.Skewness and hist history",
          href: "#",
          current: this.currentTabIdx === 1,
          isEnabled: this.selectedChartsList.includes(
            "Skewness and hist history"
          ),
        },
        {
          name: "comparison.Cumulative energy costs history",
          href: "#",
          current: this.currentTabIdx === 2,
          isEnabled: this.selectedChartsList.includes(
            "Cumulative energy costs history"
          ),
        },
      ];
    },
    isTabSelected() {
      return this.tabs.some((tab) => tab.isEnabled);
    },
  },
  watch: {
    selectedDayTrainings(newVal) {
      this.commonsData = newVal.map((i) => {
        const data = i.results.find(
          (result) => result.type === "TRAINING_COMMONS"
        );
        return {
          ...data.rowResult,
          created: data.created,
          trainingId: data.trainingId,
        };
      });
    },
    multicharts: {
      handler: async function (newVal) {
        localStorage.setItem(
          "selected-multicharts-by-days",
          JSON.stringify(newVal)
        );
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      "getHorsesList",
      "getSelectedDayTrainingsData",
      "handleTrackingMode",
    ]),
    ...mapMutations(["SET_HORSE_TRACKING_CARD"]),
    async setCurrentHorse() {
      await this.$nextTick();
      if (this.horsesList) {
        const currentHorse = this.horsesList.find(
          (h) => h.id === Number(this.$route.params.id)
        );
        if (!currentHorse) {
          return showErrorAndGoToHomePage("Horse not found");
        }
        this.SET_HORSE_TRACKING_CARD(currentHorse);
      }
    },
    async changeTraining(data) {
      this.trainings[data.type] = data;
    },
    handleSwitchTab(index) {
      this.tabs.map((i) => (i.current = false));
      this.tabs[index].current = true;
      this.currentTabIdx = index;
    },
  },

  beforeMount() {
    const defaultMulticharts = [
      {
        title: this.$t("training.Training total distance"),
        scaleId: "y",
        checked: true,
        color: "#d53e4f",
      },
      {
        title: this.$t("training.Training duration"),
        scaleId: "y1",
        checked: true,
        color: "#f46d43",
      },
      {
        title: this.$t("training.Total energy expended"),
        scaleId: "y2",
        checked: true,
        color: "#fdae61",
      },
      {
        title: this.$t("training.Average speed"),
        scaleId: "y3",
        checked: true,
        color: "#fee08b",
      },
      {
        title: this.$t("training.Max speed"),
        scaleId: "y4",
        checked: true,
        color: "#bc9b6a",
      },
      {
        title: this.$t("training.Max acceleration"),
        scaleId: "y5",
        checked: true,
        color: "#abdda4",
      },
      {
        title: this.$t("training.Average touch stiffness"),
        scaleId: "y6",
        checked: true,
        color: "#66c2a5",
      },
      {
        title: this.$t("training.Average HR"),
        scaleId: "y7",
        checked: true,
        color: "#3288bd",
      },
      {
        title: this.$t("training.Max HR"),
        scaleId: "y8",
        checked: true,
        color: "#5e4fa2",
      },
      {
        title: this.$t("tracking.Jump count"),
        scaleId: "y9",
        checked: true,
        color: "#00704a",
      },
    ];

    let selectedMulticharts = localStorage.getItem(
      "selected-multicharts-by-days"
    );
    let selectedTrackingMode = localStorage.getItem("tracking-mode");
    if (selectedMulticharts && selectedTrackingMode) {
      selectedMulticharts = JSON.parse(selectedMulticharts);
    } else {
      selectedMulticharts = defaultMulticharts;
      selectedTrackingMode = trackingModeKeys.GPS;
      localStorage.setItem(
        "selected-multicharts-by-days",
        JSON.stringify(selectedMulticharts)
      );
      localStorage.setItem("tracking-mode", selectedTrackingMode);
    }

    this.multicharts = selectedMulticharts;
    this.handleTrackingMode({ data: selectedTrackingMode });
  },

  async mounted() {
    if (!this.horsesList) await this.getHorsesList(this.$route.params.page);
    if (!this.horseTrackingCard) await this.setCurrentHorse();
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
</style>
