<template>
  <div class="mt-10 mb-4 flex justify-center items-center">
    <img
      v-for="(social, index) in socialsList" :key="index"
      :src="social.path" :alt="social.type"
      class="w-[40px] h-[40px] mx-4 cursor-pointer"
      @click="emitSocial(social.type)"
    >
  </div>
</template>

<script>
export default {
  name: "SocialComponent",
  data() {
    return {
      socialsList: [
        {
          type: 'google',
          path: require('@/assets/icons/google.svg')
        },
        {
          type: 'twitter',
          path: require('@/assets/icons/twitter.svg')
        },
        {
          type: 'facebook',
          path: require('@/assets/icons/facebook.svg')
        },
      ]
    }
  },
  methods: {
    emitSocial(social) {
      this.$emit('emittedSocial', social)
    }
  },
}
</script>