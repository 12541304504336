import { createStore } from "vuex";
import track from "@/store/track";
import calendar from "@/store/calendar";
import horses from "@/store/horses";
import trainings from "@/store/trainings";
import auth from "@/store/auth";
import trainingsHistory from "@/store/trainingsHistory";
export default createStore({
  state: {
    loading: false,
    user: null,
  },
  getters: {
    loading: (state) => state.loading,
    user: (state) => state.user,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_USER_DATA(state, data) {
      state.user = data;
    },
  },
  actions: {},
  modules: {
    track,
    calendar,
    horses,
    trainings,
    auth,
    trainingsHistory,
  },
});
