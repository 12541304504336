
export const calendarEvents = [
    {
        horseName: 'Horse_1',
        date: new Date("April 22, 2023"),
        startTime: {HH: '10', mm: '00'},
        endTime: {HH: '11', mm: '00'},
        description: 'April 22, 2023 10:00 - 11:00',
        id: '1'
    },
    {
        horseName: 'Horse_2',
        date: new Date("April 16, 2023"),
        startTime: {HH: '15', mm: '00'},
        endTime: {HH: '16', mm: '00'},
        description: 'April 16, 2023 15:00 - 16:00',
        id: '2'
    },
    {
        horseName: 'Horse_2',
        date: new Date("April 9, 2023"),
        startTime: {HH: '15', mm: '00'},
        endTime: {HH: '16', mm: '00'},
        description: 'April 9, 2023 15:00 - 16:00',
        id: '3'
    },
    {
        horseName: 'Horse_1',
        date: new Date(),
        startTime: {HH: '12', mm: '00'},
        endTime: {HH: '13', mm: '00'},
        description: 'Curr date, 2023 12:00 - 13:00',
        id: '4'
    },
    {
        horseName: 'Horse_3',
        date: new Date(),
        startTime: {HH: '18', mm: '00'},
        endTime: {HH: '19', mm: '00'},
        description: 'Curr date, 2023 18:00 - 19:00',
        id: '5'
    },
    {
        horseName: 'Horse_3',
        date: new Date("April 6, 2023"),
        startTime: {HH: '18', mm: '00'},
        endTime: {HH: '19', mm: '00'},
        description: '6, 2023 18:00 - 19:00',
        id: '6'
    },
]

