<template>
  <AuthLayoutComponent>
    <ForgotPasswordFormComponent/>
  </AuthLayoutComponent>
</template>

<script>
import AuthLayoutComponent from "@/components/layouts/AuthLayoutComponent";
import ForgotPasswordFormComponent from "@/components/auth/ForgotPasswordFormComponent";

export default {
  name: "ForgotPasswordView",
  components: {
    AuthLayoutComponent,
    ForgotPasswordFormComponent,
  }
}
</script>