<template>
  <div class="w-full flex flex-wrap justify-between items-center">
    <div
      v-for="(item, index) of mappedExpenditureEnergyData"
      :key="index"
      class="w-full xl:w-1/3 mx-auto flex flex-col justify-start items-center"
    >
      <p class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center">
        {{ $t(item.title) }}
      </p>
      <PolarArea :data="item.data" :options="options" />
      <div class="text-[14px]">
        <span class="font-semibold">{{ $t("horse.BACK") }}</span
        >: {{ item.BackSensorData }} {{ $t("training.kJ") }}
      </div>
      <div class="text-[14px]">
        <span class="font-semibold">
          {{ $t("horse.TOTAL") }}
        </span>
        :
        {{
          (
            +item.BackSensorData +
            item.data.datasets[0].data.reduce(
              (partialSum, a) => partialSum + +a,
              0
            )
          ).toFixed(2)
        }}
        {{ $t("training.kJ") }}
      </div>
    </div>
  </div>
</template>
<script>
import { Chart as ChartJS, RadialLinearScale, ArcElement } from "chart.js";
import { PolarArea } from "vue-chartjs";
import { getChartLabelPlugin } from "chart.js-plugin-labels-dv";
import { mapGetters } from "vuex";

ChartJS.register(RadialLinearScale, ArcElement, getChartLabelPlugin());
export default {
  name: "EnergyByAllurChartComponent",
  components: {
    PolarArea,
  },
  props: {
    indexColor: String,
    datasets: Array,
    cutValue: Array,
  },
  data() {
    return {
      options: {
        animation: false,
        spanGaps: true,
        decimation: true,
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: 20,
        },
        plugins: {
					crosshair: false,
          tooltip: {
            callbacks: {
              label: (el) => {
                return el.raw + " " + this.$t("training.kJ");
              },
            },
          },
          datalabels: false,
          labels: [
            {
              render: "label",
              position: "outside",
              fontSize: 16,
            },
            {
              render: (args) => {
                return args.value + this.$t("training.kJ");
              },
            },
          ],
        },
      },
      chartData: {
        datasets: [],
      },
      timeIndexes: {},
    };
  },
  watch: {
    filteredDatasets() {
      this.setTimeIndexes();
    },
  },
  mounted() {
    this.setTimeIndexes();
  },
  computed: {
    ...mapGetters(["currentTrackIndex"]),
    filteredDatasets() {
      if (!this.cutValue) {
        return this.datasets;
      } else {
        const tsCutValueFrom = this.cutValue[0] * 200;
        const tsCutValueTo = this.cutValue[1] * 200;

        return {
          walk: {
            BACK: {
              energy: this.datasets.walk.BACK.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BACK)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BACK)
                )[1]
              ),
              ts: this.datasets.walk.BACK.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BACK)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BACK)
                )[1]
              ),
            },
            FL: {
              energy: this.datasets.walk.FL.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FL)
                )[1]
              ),
              ts: this.datasets.walk.FL.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FL)
                )[1]
              ),
            },
            FR: {
              energy: this.datasets.walk.FR.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FR)
                )[1]
              ),
              ts: this.datasets.walk.FR.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.FR)
                )[1]
              ),
            },
            BL: {
              energy: this.datasets.walk.BL.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BL)
                )[1]
              ),
              ts: this.datasets.walk.BL.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BL)
                )[1]
              ),
            },
            BR: {
              energy: this.datasets.walk.BR.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BR)
                )[1]
              ),
              ts: this.datasets.walk.BR.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.walk.BR)
                )[1]
              ),
            },
          },
          trot: {
            BACK: {
              energy: this.datasets.trot.BACK.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BACK)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BACK)
                )[1]
              ),
              ts: this.datasets.trot.BACK.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BACK)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BACK)
                )[1]
              ),
            },
            FL: {
              energy: this.datasets.trot.FL.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FL)
                )[1]
              ),
              ts: this.datasets.trot.FL.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FL)
                )[1]
              ),
            },
            FR: {
              energy: this.datasets.trot.FR.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FR)
                )[1]
              ),
              ts: this.datasets.trot.FR.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.FR)
                )[1]
              ),
            },
            BL: {
              energy: this.datasets.trot.BL.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BL)
                )[1]
              ),
              ts: this.datasets.trot.BL.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BL)
                )[1]
              ),
            },
            BR: {
              energy: this.datasets.trot.BR.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BR)
                )[1]
              ),
              ts: this.datasets.trot.BR.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.trot.BR)
                )[1]
              ),
            },
          },
          gallop: {
            BACK: {
              energy: this.datasets.gallop.BACK.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BACK)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BACK)
                )[1]
              ),
              ts: this.datasets.gallop.BACK.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BACK)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BACK)
                )[1]
              ),
            },
            FL: {
              energy: this.datasets.gallop.FL.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FL)
                )[1]
              ),
              ts: this.datasets.gallop.FL.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FL)
                )[1]
              ),
            },
            FR: {
              energy: this.datasets.gallop.FR.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FR)
                )[1]
              ),
              ts: this.datasets.gallop.FR.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.FR)
                )[1]
              ),
            },
            BL: {
              energy: this.datasets.gallop.BL.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BL)
                )[1]
              ),
              ts: this.datasets.gallop.BL.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BL)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BL)
                )[1]
              ),
            },
            BR: {
              energy: this.datasets.gallop.BR.energy.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BR)
                )[1]
              ),
              ts: this.datasets.gallop.BR.ts.slice(
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BR)
                )[0],
                this.setSliceIndexes(
                  tsCutValueFrom,
                  tsCutValueTo,
                  this.setMappedTiming(this.datasets.gallop.BR)
                )[1]
              ),
            },
          },
        };
      }
    },
    mappedExpenditureEnergyData() {
      const labels = [
        this.$t("horse.FR"),
        this.$t("horse.HR"),
        this.$t("horse.HL"),
        this.$t("horse.FL"),
      ];
      if (this.timeIndexes.walk) {
        const index = this.cutValue
          ? (this.currentTrackIndex + this.cutValue[0]) * 200
          : this.currentTrackIndex * 200;

        const walk = this.setDataByType(
          "tracking.Walk",
          labels,
          this.filteredDatasets.walk,
          index,
          "walk"
        );
        const trot = this.setDataByType(
          "tracking.Trot",
          labels,
          this.filteredDatasets.trot,
          index,
          "trot"
        );
        const gallop = this.setDataByType(
          "tracking.Gallop",
          labels,
          this.filteredDatasets.gallop,
          index,
          "gallop"
        );

        return [walk, trot, gallop];
      }
      return [];
    },
  },
  methods: {
    setSliceIndexes(from, to, data) {
      const fromIndex = data.findIndex((i) => i >= from);
      const toIndex = data.findIndex((i) => i >= to);

      return [
        fromIndex === -1 ? 0 : fromIndex,
        toIndex === -1 ? data.length - 1 : toIndex,
      ];
    },
    setDataByType(title, labels, data, indexIs, type) {
      return {
        title: title,
        BackSensorData: this.setBackSensorData(data, indexIs, type),
        data: {
          labels: labels,
          datasets: [
            {
              backgroundColor: ["#8664c4", "#f6c536", "#f88b30", "#488ff8"],
              data: this.setData(data, indexIs, type),
              labels: {
                color: "#ffffff",
              },
            },
          ],
        },
      };
    },
    setBackSensorData(data, indexIs, type) {
      const BackIndex = this.timeIndexes[type].BACK.findIndex(
        (i) => i >= indexIs
      );

      return this.setLegData(data.BACK.energy, BackIndex);
    },
    setData(data, indexIs, type) {
      const FLindex = this.timeIndexes[type].FL.findIndex((i) => i >= indexIs);
      const FRindex = this.timeIndexes[type].FR.findIndex((i) => i >= indexIs);
      const BLindex = this.timeIndexes[type].BL.findIndex((i) => i >= indexIs);
      const BRindex = this.timeIndexes[type].BR.findIndex((i) => i >= indexIs);

      return [
        this.setLegData(data.FR.energy, FRindex),
        this.setLegData(data.BR.energy, BRindex),
        this.setLegData(data.BL.energy, BLindex),
        this.setLegData(data.FL.energy, FLindex),
      ];
    },
    setLegData(data, index) {
      if (index === -1)
        return (
          +data.reduce((partialSum, a) => partialSum + a, 0) / 1000
        ).toFixed(2);
      else if (index === 0) return 0;
      else
        return (
          +data.slice(0, +index).reduce((partialSum, a) => partialSum + a, 0) /
          1000
        ).toFixed(2);
    },
    convertTimeToMs(str) {
      let times = str.split(":");
      const hours = +times[0] * 3600 * 1000;
      const minutes = +times[1] * 60 * 1000;
      const seconds = +times[2] * 1000;
      return hours + minutes + seconds;
    },
    setTimeIndexes() {
      if (this.filteredDatasets) {
        if (!this.filteredDatasets.walk) return;
        this.timeIndexes = {
          walk: {
            FL: this.setMappedTiming(this.filteredDatasets.walk.FL),
            FR: this.setMappedTiming(this.filteredDatasets.walk.FR),
            BL: this.setMappedTiming(this.filteredDatasets.walk.BL),
            BR: this.setMappedTiming(this.filteredDatasets.walk.BR),
            BACK: this.setMappedTiming(this.filteredDatasets.walk.BACK),
          },
          trot: {
            FL: this.setMappedTiming(this.filteredDatasets.trot.FL),
            FR: this.setMappedTiming(this.filteredDatasets.trot.FR),
            BL: this.setMappedTiming(this.filteredDatasets.trot.BL),
            BR: this.setMappedTiming(this.filteredDatasets.trot.BR),
            BACK: this.setMappedTiming(this.filteredDatasets.trot.BACK),
          },
          gallop: {
            FL: this.setMappedTiming(this.filteredDatasets.gallop.FL),
            FR: this.setMappedTiming(this.filteredDatasets.gallop.FR),
            BL: this.setMappedTiming(this.filteredDatasets.gallop.BL),
            BR: this.setMappedTiming(this.filteredDatasets.gallop.BR),
            BACK: this.setMappedTiming(this.filteredDatasets.gallop.BACK),
          },
        };
      }
    },
    setMappedTiming(data) {
      return data.ts.map((i) => {
        return this.convertTimeToMs(i.slice(0, 8));
      });
    },
  },
};
</script>
