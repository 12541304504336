<template>
  <div class="table-wrapper h-fit p-0 bg-white">
    <table class="table text-left w-full whitespace-nowrap table-fixed text-sm">
      <tbody>
        <tr
          v-for="item in tableData"
          :key="item.name"
          :class="{ 'opacity-25 cursor-not-allowed': item.disabled }"
        >
          <td class="w-2/3 py-2 px-3 font-bold">
            {{ item.name }}
          </td>
          <td class="w-1/3 py-2 px-3">
            {{ item.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TableComponent",
  props: {
    tableData: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  border-radius: 8px;
  border: 1px solid #b4b6b9;
  overflow: auto;
}

table {
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #b4b6b9;
    border-width: 0 1px 1px 0;
    text-wrap: balance;
  }

  thead tr {
    th:last-child {
      border-right-width: 0;
      border-left-width: 1px;
    }
    td:last-child {
      border-right-width: 0;
    }
  }

  tbody {
    tr:last-child {
      border-bottom-width: 0;

      td {
        border-bottom-width: 0;
      }
    }

    td {
      &:nth-child(n + 2):last-child {
        border-right-width: 0;
      }
    }
  }
}
</style>
