import { useToast } from "vue-toastification";
import api from "@/axiosConfig";
const toast = useToast();
import i18n from "@/translations";

const state = {
  horsesList: null,
  horseTrackingCard: null,
  totalElements: 1,

  sharingInList: [],
  sharingOutList: [],
};

const getters = {
  horsesList: (state) => state.horsesList,
  totalElements: (state) => state.totalElements,
  horseTrackingCard: (state) => state.horseTrackingCard,

  sharingInList: (state) => state.sharingInList,
  sharingOutList: (state) => state.sharingOutList,
};

const mutations = {
  SET_HORSES_LIST(state, data) {
    state.horsesList = data;
  },
  SET_TOTAL_ELEMENTS(state, data) {
    state.totalElements = data;
  },
  SET_HORSE_TRACKING_CARD(state, data) {
    state.horseTrackingCard = data;
  },
  SET_SHARING_IN_LIST(state, data) {
    state.sharingInList = data;
  },
  SET_SHARING_OUT_LIST(state, data) {
    state.sharingOutList = data;
  },
};

const actions = {
  async getHorsesList({ commit }, pageNumber) {
    return api.get("horses", { params: { page: pageNumber } }).then((res) => {
      commit("SET_HORSES_LIST", res.data.content);
      commit("SET_TOTAL_ELEMENTS", res.data.totalElements);
    });
  },
  async addHorse(_, data) {
    await api
      .post("horses", data)
      .then(() =>
        toast.success(
          i18n.global.t("add-horse.Horse profile have been created")
        )
      )
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async updateHorse(_, data) {
    return await api
      .put(`horses/${data.id}`, data.horseData)
      .then(() =>
        toast.success(
          i18n.global.t("add-horse.Horse details have been changed")
        )
      )
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async deleteHorse(_, id) {
    return await api
      .delete(`horses/${id}`)
      .then(() =>
        toast.success(
          i18n.global.t("add-horse.Horse profile have been deleted")
        )
      )
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async getHorseTrainingHistory(_, id) {
    return api.get(`horses/${id}/trainings`).then((res) => {
      // commit('SET_HORSES_LIST', res.data.content)
      console.log(res);
    });
  },

  async shareHorse(_, data) {
    return api
      .post(
        `sharing/out/request-to-user?horse-id=${data.horseId}&email=${data.email}`
      )
      .then((res) => {
        if (!res.data.success) {
          toast.error(res.data.error.error_message);
        } else {
          toast.success(
            i18n.global.t("share.Horse data was sent to user") +
              " " +
              data.email
          );
          console.log(res);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async getSharingInList({ commit }) {
    return api
      .get(`sharing/in/requests`)
      .then((res) => {
        commit("SET_SHARING_IN_LIST", res.data.requests);
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async getSharingOutList({ commit }) {
    return api
      .get(`sharing/out/requests`)
      .then((res) => {
        commit("SET_SHARING_OUT_LIST", res.data.requests);
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async acceptSharingInRequest(_, id) {
    return api
      .post(`sharing/in/requests/${id}/accept`)
      .then((res) => {
        if (!res.data.success) {
          toast.error(res.data.error.error_message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async deleteSharingInRequest(_, id) {
    return api
      .post(`sharing/in/requests/${id}/decline`)
      .then((res) => {
        if (!res.data.success) {
          toast.error(res.error.error_message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async deleteSharingOutRequest(_, id) {
    return api
      .delete(`sharing/out/requests/${id}`)
      .then((res) => {
        if (!res.data.success) {
          toast.error(res.error.error_message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
