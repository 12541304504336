<template>
  <AuthLayoutComponent>
    <LoginFormComponent/>
  </AuthLayoutComponent>
</template>

<script>
import AuthLayoutComponent from "@/components/layouts/AuthLayoutComponent";
import LoginFormComponent from "@/components/auth/LoginFormComponent";

export default {
  name: "LoginView",
  components: {
    AuthLayoutComponent,
    LoginFormComponent,
  }
}
</script>