<template>
  <div
    class="w-full mx-auto my-4 p-4 flex justify-between flex-wrap bg-white shadow rounded-lg relative border-2"
  >
    <div
      v-if="filteredEnergyData && !filteredEnergyData.labels"
      class="w-full my-8 flex justify-center items-center"
    >
      <span>{{ $t("comparison.Nothing to compare") }}.</span>
    </div>
    <LoaderComponent v-if="loading" />
    <div
      v-if="filteredEnergyData.labels"
      class="w-full my-4 flex flex-wrap justify-center items-center"
    >
      <p
        class="w-full text-[#5A5A5F] text-[18px] font-semibold text-center my-4"
      >
        {{ $t("comparison.Cumulative energy costs history") }}
      </p>
      <div class="w-full flex flex-wrap justify-center items-center">
        <div
          v-for="(i, index) of filteredEnergyData.data"
          :key="i + index"
          class="m-4 w-full md:w-[45%]"
        >
          <p
            class="text-[#5A5A5F] text-[16px] font-poppins font-semibold text-center"
          >
            {{ $t("tracking." + i.title) }}
          </p>
          <Bar :data="i.chartData" :options="options" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
} from "chart.js";
import { enUS, ru } from "date-fns/locale";
import LoaderComponent from "@/components/LoaderComponent.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  TimeScale
);
export default {
  name: "CumulativeEnergyComparison",
  components: { LoaderComponent, Bar },
  data() {
    return {
      movements: [
        { title: "Walk", checked: true },
        { title: "Trot", checked: true },
        { title: "Gallop", checked: true },
      ],
      options: {
        animation: false,
        spanGaps: true,
        decimation: true,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          crosshair: false,
          datalabels: false,
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                const date = new Date(tooltipItem[0].label);
                const locale = this.$i18n.locale === "Ru" ? ru : enUS;
                return format(date, "dd MMM yy", { locale: locale });
              },
              label: (el) => {
                return el.parsed.y + " " + this.$t("training.kJ");
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              major: true,
              callback: (val) => {
                return format(this.filteredEnergyData.labels[val], "dd-MM-yy");
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["energyCoastHistory", "loading"]),
    filteredEnergyData() {
      if (!this.energyCoastHistory) return [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const sortedData = this.energyCoastHistory.sort(
        (a, b) => a.trainingDate - b.trainingDate
      );
      sortedData.map(
        (i) =>
          (i.trainingData = i.trainingData
            ? i.trainingData
            : {
                gallop: { BACK: 0, BL: 0, BR: 0, FL: 0, FR: 0 },
                walk: { BACK: 0, BL: 0, BR: 0, FL: 0, FR: 0 },
                trot: { BACK: 0, BL: 0, BR: 0, FL: 0, FR: 0 },
              })
      );

      const labels = sortedData.map((i) => i.trainingDate);
      const walk = sortedData.map(
        (i) =>
          +(
            +i.trainingData.walk.BACK.total_energy / 1000 +
            +i.trainingData.walk.FL.total_energy / 1000 +
            +i.trainingData.walk.FR.total_energy / 1000 +
            +i.trainingData.walk.BL.total_energy / 1000 +
            +i.trainingData.walk.BR.total_energy / 1000
          ).toFixed(0)
      );
      const trot = sortedData.map(
        (i) =>
          +(
            +i.trainingData.trot.BACK.total_energy / 1000 +
            +i.trainingData.trot.FL.total_energy / 1000 +
            +i.trainingData.trot.FR.total_energy / 1000 +
            +i.trainingData.trot.BL.total_energy / 1000 +
            +i.trainingData.trot.BR.total_energy / 1000
          ).toFixed(0)
      );
      const gallop = sortedData.map(
        (i) =>
          +(
            +i.trainingData.gallop.BACK.total_energy / 1000 +
            +i.trainingData.gallop.FL.total_energy / 1000 +
            +i.trainingData.gallop.FR.total_energy / 1000 +
            +i.trainingData.gallop.BL.total_energy / 1000 +
            +i.trainingData.gallop.BR.total_energy / 1000
          ).toFixed(0)
      );
      const all = walk.map((i, index) => i + trot[index] + gallop[index]);

      return {
        labels: labels,
        data: [
          {
            title: "All training",
            chartData: {
              labels: labels,
              datasets: [
                {
                  backgroundColor: "#6A9A53",
                  barThickness: 30,
                  data: all,
                },
              ],
            },
          },
          {
            title: "Walk",
            chartData: {
              labels: labels,
              datasets: [
                {
                  backgroundColor: "#1AB0B0",
                  barThickness: 30,
                  data: walk,
                },
              ],
            },
          },
          {
            title: "Trot",
            chartData: {
              labels: labels,
              datasets: [
                {
                  backgroundColor: "#FFCD4B",
                  barThickness: 30,
                  data: trot,
                },
              ],
            },
          },
          {
            title: "Gallop",
            chartData: {
              labels: labels,
              datasets: [
                {
                  backgroundColor: "#F85C7F",
                  barThickness: 30,
                  data: gallop,
                },
              ],
            },
          },
        ],
      };
    },
  },
};
</script>
