import { createI18n } from 'vue-i18n'
import en from './locales/En.json';
import ru from './locales/Ru.json';
import lv from './locales/Lv.json';

const i18n = createI18n({
  locale: 'en',
  messages: {
    En: en,
    Ru: ru,
    Lv: lv,
  },
})
export default i18n