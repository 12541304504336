<template>
  <div
    :class="{ '2xl:block opacity-25 cursor-not-allowed': disabled }"
    class="h-full-available bg-white drop-shadow-md rounded-lg p-4 flex flex-col justify-start items-center"
  >
    <div
      class="max-w-[600px] w-full flex flex-col-reverse 2xl:flex-row justify-between flex-wrap"
    >
      <div
        class="w-full 2xl:w-1/2 h-[160px] flex flex-col items-center justify-center mt-4"
      >
        <div class="max-w-[200px] mx-auto sm:mx-0 mb-4 ms:mb-0">
          <div class="relative">
            <Doughnut
              :data="donutData"
              :options="options"
              ref="doughnutChart"
            />
            <div
              v-if="comparedData"
              class="absolute w-[90px] text-center left-[calc(50%_-_47px)] top-[40%] flex flex-col justify-center items-center text-[#191A1E] text-[16px] font-semibold"
            >
              <span>{{ comparedData }}</span>
              <span>{{ type }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full 2xl:w-[47%] 2xl:ml-1">
        <div class="w-full flex flex-col tracking-wider">
          <p
            class="text-[#5A5A5F] text-[16px] font-semibold text-center 2xl:text-start mb-4"
          >
            {{ title }}
          </p>
          <div
            v-for="(i, index) of listData"
            :key="index"
            class="w-full flex justify-between my-1"
          >
            <div class="flex items-center">
              <div
                class="w-[10px] h-[10px] rounded-full"
                :style="{ backgroundColor: i.color }"
              ></div>
              <p
                class="ml-2 text-[#0A0A0A] text-[18px] lg:text-[16px] font-semibold"
              >
                {{ $t("tracking." + i.key) }}
              </p>
            </div>
            <p
              class="text-[#9D9D9D] lg:text-[14px] xl:text-[16px] 2xl:text-[14px] flex items-center"
            >
              {{ i.value }} {{ type }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  CategoryScale,
  ChartDataLabels
);
export default {
  name: "DoughnutChartComponent",
  components: {
    Doughnut,
  },
  props: {
    title: String,
    type: String,
    buttonTitle: String,
    data: Object,
    trackingData: Array,
    comparedData: Number,
    disabled: Boolean,
    chartData: Object,
  },
  data() {
    return {
      colors: [
        "#1AB0B0",
        "#FFCD4B",
        "#F85C7F",
        "#A0A0A0",
        "#1AB0B0",
        "#FFCD4B",
        "#F85C7F",
        "#1AB0B0",
        "#FFCD4B",
        "#F85C7F",
      ],
      options: {
        cutout: 50,
        layout: {
          padding: {
            left: 37,
            right: 40,
            top: 30,
            bottom: 30,
          },
        },
        plugins: {
          crosshair: false,
          datalabels: {
            color: "#9D9D9D",
            align: "end",
            anchor: "end",
            font: {
              size: 12,
              weight: "600",
            },
            formatter: function (value, context) {
              return context.dataset.data[context.dataIndex] > 0
                ? Math.ceil(context.dataset.data[context.dataIndex]) + "%"
                : null;
            },
            padding: 2,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
  computed: {
    donutData() {
      if (!this.data?.length) {
        return {
          labels: [],
          datasets: [],
        };
      }

      return {
        labels: this.data ? this.data.map((i) => i.title) : [],
        datasets: [
          {
            backgroundColor: this.colors,
            data: this.data.map((i) => i.percent),
          },
        ],
      };
    },
    listData() {
      if (!this.data) return null;
      let data = [];
      this.data.map((i, index) => {
        data.push({
          key: i.title,
          value: i.data,
          color: this.colors[index],
        });
      });
      return data;
    },
  },
  methods: {
    handleResize() {
      const chart = this.$refs.doughnutChart.chart;
      if (chart) {
        chart.destroy();
        this.renderChart();
      }
    },

    renderChart() {
      const chartRef = this.$refs.doughnutChart;
      const ctx = chartRef.$el.getContext("2d");

      if (chartRef.chart) {
        chartRef.chart.destroy();
      }

      // Create a new chart instance
      chartRef.chart = new ChartJS(ctx, {
        type: "doughnut",
        data: this.donutData,
        options: this.options,
      });
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
