<template>
  <AuthLayoutComponent>
    <BlockedAccountComponent/>
  </AuthLayoutComponent>
</template>

<script>
import AuthLayoutComponent from "@/components/layouts/AuthLayoutComponent";
import BlockedAccountComponent from "@/components/auth/BlockedAccountComponent";

export default {
  name: "BlockedAccountView",
  components: {
    AuthLayoutComponent,
    BlockedAccountComponent,
  }
}
</script>