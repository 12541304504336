<template>
  <HeaderComponent @emitSwitchSidebar="isSidebarOpen = !isSidebarOpen" />
  <SidebarComponent :isSidebarOpen="isSidebarOpen">
    <slot></slot>
  </SidebarComponent>
  <FooterComponent />
</template>

<script>
import HeaderComponent from "@/components/blocks/header/HeaderComponent.vue";
import SidebarComponent from "@/components/blocks/sidebar/SidebarComponent.vue";
import FooterComponent from "@/components/blocks/FooterComponent.vue";
import routeNames from "@/router/routes";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "HomeLayoutComponent",
  components: {
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
  },
  data() {
    return {
      isSidebarOpen: true,
    };
  },
  methods: {
    ...mapActions(["getSettings"]),
    ...mapMutations(["SET_RANGE_HISTORY"]),
  },
  async mounted() {
    await this.getSettings();
    // reset comparing dates
    if (this.$route.name === routeNames.horses) {
      this.SET_RANGE_HISTORY(null);
    }
  },
};
</script>
