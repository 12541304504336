<template>
  <AuthLayoutComponent>
    <RegistrationFormComponent/>
  </AuthLayoutComponent>
</template>

<script>
import AuthLayoutComponent from "@/components/layouts/AuthLayoutComponent";
import RegistrationFormComponent from "@/components/auth/RegistrationFormComponent";

export default {
  name: "RegistrationView",
  components: {
    AuthLayoutComponent,
    RegistrationFormComponent,
  }
}
</script>