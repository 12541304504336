<template>
  <HomeLayoutComponent>
    <HorsesListComponent />
  </HomeLayoutComponent>
</template>

<script>
import HomeLayoutComponent from "@/components/layouts/HomeLayoutComponent";
import HorsesListComponent from "@/components/horsesList/HorsesListComponent";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "HorsesView",
  components: {
    HomeLayoutComponent,
    HorsesListComponent,
  },
  computed: {
    ...mapGetters(["horsesList"]),
  },
  async mounted() {
    this.SET_LOADING(true);
    this.SET_SELECTED_TRAINING_DATE(null);
    await this.getHorsesList(this.$route.params.page);
    await this.getSharingOutList();
    await this.getSharingInList();

    this.SET_ENERGY_COAST_HISTORY(null);
    this.SET_SKEWNESS_HISTORY(null);
    this.SET_LOADING(false);
  },
  methods: {
    ...mapActions(["getHorsesList", "getSharingOutList", "getSharingInList"]),
    ...mapMutations([
      "SET_LOADING",
      "SET_SELECTED_TRAINING_DATE",
      "SET_SKEWNESS_HISTORY",
      "SET_ENERGY_COAST_HISTORY",
    ]),
  },
};
</script>
