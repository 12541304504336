<template>
  <HomeLayoutComponent>
    <LoaderComponent v-if="loading"/>
    <CalendarComponent v-else/>
  </HomeLayoutComponent>
</template>

<script>
import HomeLayoutComponent from "@/components/layouts/HomeLayoutComponent";
import CalendarComponent from "@/components/taskboard/CalendarComponent";
import LoaderComponent from "@/components/LoaderComponent";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "TaskboardView",
  components: {
    HomeLayoutComponent,
    CalendarComponent,
    LoaderComponent,
  },
  computed: {
    ...mapGetters(['loading', 'horsesList', 'calendarEvents'])
  },
  async mounted() {
    this.SET_LOADING(true)
    if(!this.calendarEvents) await this.getEvents()
    if(!this.horsesList) await this.getHorsesList()
    this.SET_LOADING(false)
  },
  methods: {
    ...mapActions(['getEvents', 'getHorsesList']),
    ...mapMutations(['SET_LOADING']),
  },
}
</script>