<template>
  <HomeLayoutComponent>
    <div class="h-full flex justify-center items-center">
      Something went wrong...
    </div>
  </HomeLayoutComponent>
</template>

<script>
import HomeLayoutComponent from "@/components/layouts/HomeLayoutComponent.vue";

export default {
  name: "ErrorPageView",
  components: {
    HomeLayoutComponent,
  },
};
</script>
