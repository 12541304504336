<template>
  <footer class="bg-white drop-shadow-md">
    <div
      class="px-6 mt-4 md:mt-0 flex flex-col md:flex-row items-center justify-between"
    >
      <p class="text-center text-xs leading-5 text-gray-500">
        &copy; {{ currentYear }} Horsecare, Inc. All rights reserved.
      </p>
      <ul
        role="list"
        class="p-2 flex flex-col md:flex-row justify-between items-center"
      >
        <li class="font-poppins text-[#5A5A5F]">
          <button
            @click.prevent="
              handleClick(
                'https://play.google.com/store/apps/details?id=com.horsehealthdatareceiver'
              )
            "
            class="flex justify-start items-center h-[44px] w-full px-2"
            :class="{ 'text-gray-500, cursor-default': false }"
          >
            <img
              width="24"
              height="24"
              src="https://img.icons8.com/ios/50/android-os.png"
              alt="android-os"
              class="mr-1"
            />
            <span class="text-[14px]">{{ $t("Android App") }}</span>
          </button>
        </li>
        <li class="font-poppins text-[#5A5A5F]">
          <button
            @click.prevent="
              handleClick('https://www.horsecare.si/privacy-policy/')
            "
            class="flex justify-start items-center h-[44px] w-full px-2"
            :class="{ 'text-gray-500, cursor-default': false }"
          >
            <img
              width="24"
              height="24"
              src="https://img.icons8.com/ios/50/ios-logo.png"
              alt="ios-logo"
              class="mr-1"
            />
            <span class="text-[14px]">{{ $t("Ios App") }}</span>
          </button>
        </li>
        <li class="font-poppins text-[#5A5A5F]">
          <button
            @click.prevent="
              handleClick('https://www.horsecare.si/privacy-policy/')
            "
            class="flex justify-start items-center h-[44px] w-full px-2"
            :class="{ 'text-gray-500, cursor-default': false }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-[24px] h-[24px] mr-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
              />
            </svg>
            <span class="text-[14px]">{{ $t("Privacy policy") }}</span>
          </button>
        </li>
        <li class="font-poppins text-[#5A5A5F]">
          <button
            @click.prevent="handleClick('https://www.horsecare.si/faq-1/')"
            class="flex justify-start items-center h-[44px] w-full px-2"
            :class="{ 'text-gray-500, cursor-default': false }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-[24px] h-[24px] mr-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008"
              />
            </svg>
            <span class="text-[14px]">{{ $t("FAQ") }}</span>
          </button>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      userNavigation: [
        {
          title: "Privacy policy",
          disabled: false,
          href: "https://www.horsecare.si/privacy-policy/",
          img: "M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z",
        },
        {
          title: "FAQ",
          disabled: false,
          href: "https://www.horsecare.si/faq-1/",
          img: "M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008",
        },
      ],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    handleClick(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
