<template>
  <TransitionRoot as="template" class="z-50" :show="isOpen">
    <Dialog as="div" class="relative z-1000" @close="closeModal('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4"
              :class="{
                'max-w-[450px]': small,
                'w-full md:w-[1216px]': medium,
                'w-full max-w-[2000px]': large,
              }"
            >
              <div
                v-if="xMark"
                class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
              >
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  @click="closeModal('close')"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div
                class="bg-whit"
                :class="small ? 'p-2' : ' px-4 pb-4 pt-5 sm:p-6 sm:pb-4'"
              >
                <div class="w-full">
                  <div
                    class="mt-3 text-center sm:pt-4 sm:px-4 sm:mt-0 sm:text-left"
                  >
                    <DialogTitle as="h3" class="text-[24px] mb-4">
                      <slot name="title"></slot>
                    </DialogTitle>
                    <slot name="content"></slot>
                  </div>
                </div>
              </div>
              <div class="pb-4 flex flex-wrap justify-between sm:px-6">
                <div>
                  <slot name="accept"></slot>
                </div>
                <div
                  v-if="!confirmHidden"
                  class="flex justify-end w-full"
                  :class="small ? '' : 'md:w-1/4'"
                >
                  <Button
                    @click="closeModal('close')"
                    cancel-style
                    class="mx-2 px-6"
                    >{{ $t("Cancel") }}</Button
                  >
                  <Button
                    @click="closeModal('accept')"
                    :disabled="isConfirmDisabled"
                    class="mx-2 px-6"
                  >
                    {{ $t("Accept") }}
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import Button from "@/components/UI/Button";

export default {
  name: "ModalComponent",
  components: {
    TransitionRoot,
    TransitionChild,
    DialogPanel,
    DialogTitle,
    Dialog,
    Button,
    XMarkIcon,
  },
  props: {
    isOpen: Boolean,
    xMark: Boolean,
    confirmHidden: Boolean,
    isConfirmDisabled: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
  },
  // watch: {
  //   isOpen() {
  //     this.open = this.isOpen
  //   }
  // },
  // data() {
  //   return {
  //     open: false,
  //   }
  // },
  methods: {
    closeModal(val) {
      val === "close" ? this.$emit("handleCancel") : this.$emit("handleAccept");
    },
  },
};
</script>
