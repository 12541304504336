<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope"
  >
    <div class="w-full min-h-[60px] flex justify-center items-center relative">
      <BackButtonComponent />
      <HorseInfoComponent
        v-if="horseTrackingCard && reports && reports.steps_map"
        :horseTrackingCard="horseTrackingCard"
      />
    </div>
    <div
      v-if="reports && reports.TRACKING"
      class="w-full mx-auto my-4 p-4 flex flex-col justify-between flex-wrap bg-white shadow rounded-lg relative"
    >
      <p
        class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center mb-4"
      >
        {{ $t("tracking.Tracking center of gravity") }}
      </p>
      <div class="w-full flex flex-wrap justify-center items-center border-2">
        <HorseTrackingComponent
          :tracking="
            cutValue ? mappedData.slice(cutValue[0], cutValue[1]) : mappedData
          "
          :index="index"
          :lengthPreviousValues="historyLength"
          :fromToCutValue="fromToCutValue"
          :SliceFromIndex="cutValue ? cutValue[0] : null"
          :SliceToIndex="cutValue ? cutValue[1] : null"
        />
        <CenterOfGravityChartComponent
          :tracking="
            cutValue ? mappedData.slice(cutValue[0], cutValue[1]) : mappedData
          "
          :index="index"
          :lengthPreviousValues="historyLength"
        />
      </div>
      <div
        class="w-200px mx-auto flex flex-col justify-start items-center mt-4"
      >
        <span class="text-[#5A5A5F] text-[18px] font-semibold"
          >{{ $t("tracking.Horse gait history") }} =
          {{ (historyLength / 5).toFixed(0) }}
          {{ $t("tracking.seconds") }}</span
        >
        <Slider
          class="w-full h-1 mt-2 mb-4 cursor-pointer"
          :tooltips="false"
          v-model="historyLength"
          :max="1500"
          :min="50"
        />
      </div>
      <div class="flex justify-center items-center mt-2">
        <div
          v-for="(item, index) of movements"
          :key="index"
          class="flex items-center mx-1"
        >
          <span
            class="w-[10px] h-[10px] mx-1 rounded-full"
            :class="getClass(item.backgroundColor)"
          ></span>
          <span class="text-[#5A5A5F] text-[18px]">{{
            this.$t(item.title)
          }}</span>
        </div>
      </div>
      <div class="mt-8 mb-4 mx-8">
        <PlayControllerSliderComponent
          :trackingMarkersLength="
            cutValue
              ? mappedData.slice(cutValue[0], cutValue[1]).length - 1
              : mappedData.length - 1
          "
          @updateIndex="updateIndex"
        />
      </div>
      <div class="mt-8 mb-4 mx-8">
        <CutTrainingComponent
          :trackingMarkersLength="reports.TRACKING.x.length"
          @cutTrackingValue="cutTrackingValue"
          @changeCutValue="changeCutValue"
        />
      </div>
    </div>
    <div
      v-else-if="reports?.TREND && !reports?.TRACKING"
      class="mt-64 text-[#5A5A5F] text-[21px] font-semibold text-center"
    >
      {{ $t("tracking.No data available for this workout") }}
    </div>
  </div>
</template>
<script>
import HorseInfoComponent from "@/components/training/HorseInfoComponent.vue";
import BackButtonComponent from "@/components/BackButtonComponent.vue";
import { mapGetters, mapMutations } from "vuex";
import PlayControllerSliderComponent from "@/components/training/tracking/PlayControllerSliderComponent.vue";
import CenterOfGravityChartComponent from "@/components/training/tracking/centerOfGravityTracking/CenterOfGravityChartComponent.vue";
import Slider from "@vueform/slider";
import CutTrainingComponent from "@/components/training/tracking/CutTrainingComponent.vue";
import HorseTrackingComponent from "@/components/training/tracking/HorseTrackingComponent.vue";

import { getClass } from "@/components/training/helpers";
import { movements } from "@/components/training/constants";

export default {
  name: "CenterOfGravityMainComponent",
  components: {
    HorseTrackingComponent,
    CutTrainingComponent,
    BackButtonComponent,
    HorseInfoComponent,
    PlayControllerSliderComponent,
    CenterOfGravityChartComponent,
    Slider,
  },
  data() {
    return {
      movements,
      index: 0,
      historyLength: 50,
      cutValue: null,
      fromToCutValue: [],
    };
  },
  computed: {
    ...mapGetters(["horseTrackingCard", "reports"]),
    mappedData() {
      if (!this.reports.TRACKING.x) return [];
      else {
        return this.reports.TRACKING.x.map((i, index) => {
          return {
            x: this.reports.TRACKING.x[index],
            y: this.reports.TRACKING.y[index],
            phi: this.reports.TRACKING.phi
              ? this.convertToDeg(this.reports.TRACKING.phi[index])
              : 0,
            radius: this.reports.TRACKING.radius
              ? this.reports.TRACKING.radius[index]
              : 0,
            ts: this.reports.TRACKING.ts ? this.reports.TRACKING.ts[index] : 0,
            color: this.reports.TRACKING.gait
              ? this.setColor(this.reports.TRACKING.gait[index])
              : "grey",
            id: index,
          };
        });
      }
    },
  },
  methods: {
    ...mapMutations(["SET_REPORTS_DATA", "SET_HORSE_LAMENESS"]),
    getClass,
    convertToDeg(rad) {
      const pi = Math.PI;
      return rad * (180 / pi);
    },
    setColor(type) {
      if (type === "stay") return "grey";
      else if (type === "walk") return "#1AB0B0";
      else if (type === "trot") return "#FFCD4B";
      else if (type === "gallop") return "#F85C7F";
    },
    updateIndex(val) {
      this.index = val;
    },
    cutTrackingValue(val) {
      this.cutValue = val;
      this.index = 0;
    },
    changeCutValue(val) {
      this.fromToCutValue = [
        this.mappedData[val[0]].id,
        this.mappedData[val[1] - 1].id,
      ];
    },
  },
  beforeUnmount() {
    this.SET_REPORTS_DATA(null);
    this.SET_HORSE_LAMENESS(null);
  },
};
</script>
