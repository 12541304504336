<template>
  <div class="h-[45px] flex justify-center items-center mb-4 mt-4">
    <input
      :disabled="disabled"
      :id="id"
      @change="handleChange"
      type="checkbox"
      class="w-[28px] h-[28px] appearance-none focus:ring-0 focus:ring-offset-0 checked:text-[#9A8053] mr-2"
      v-model="fieldData"
    >
    <label :for="id" class="text-[10px] font-semibold">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxControl",
  props: {
    dataKey: String,
    id: String,
    value: Boolean,
    disabled: Boolean,
  },
  watch: {
    value() {
      this.fieldData = this.value
    }
  },
  mounted() {
    this.fieldData = this.value
  },
  data() {
    return {
      fieldData: false
    }
  },
  methods: {
    handleChange() {
      this.$emit('update', {key: this.dataKey, data: this.fieldData})
    },
  },
}
</script>