function getSecondsFromMinutesTimestamp(timeString) {
  const [minutes, seconds] = timeString.split(":").map(Number);
  return minutes * 60 + seconds;
}

function getMinutesTimestampFromSeconds(seconds) {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0");
  const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
  return `${minutes}:${remainingSeconds}`;
}

function getBackgroundColorByGait(gaitType) {
  switch (gaitType) {
    case "walk":
      return "rgba(26, 176, 176, 0.3)";
    case "trot":
      return "rgba(255, 205, 75, 0.3)";
    case "gallop":
      return "rgba(248, 92, 127, 0.3)";
    default:
      return "rgba(192, 192, 192, 0.3)";
  }
}

function setLocalZoomData(chart) {
  const minMax = {};
  const chartScales = ["x", "y", "y1", "y2", "y3", "y4", "y5", "y6", "y7"];
  chartScales.forEach((axisId) => {
    const display = chart.options.scales[axisId].display;
    minMax[axisId] = {
      min: display ? chart.scales[axisId].min : undefined,
      max: display ? chart.scales[axisId].max : undefined,
    };
  });
  return minMax;
}

function setScalesMinMax(chart, localZoom) {
  const chartScales = ["x", "y", "y1", "y2", "y3", "y4", "y5", "y6", "y7"];
  chartScales.forEach((axisId) => {
    chart.options.scales[axisId].min = localZoom[axisId]?.min;
    chart.options.scales[axisId].max = localZoom[axisId]?.max;
  });
}

function generateZeroLine(id) {
  return {
    type: "line",
    drawTime: "beforeDatasetsDraw",
    mode: "horizontal",
    scaleID: id,
    value: 0,
    borderColor: "gray",
    borderWidth: 1,
    borderDash: [5, 5],
  };
}

function generateDataByTrainingTime(
  trainingTime,
  dataSecondsArray,
  dataArray,
  threshold
) {
  const results = [];
  trainingTime.forEach((timeLabel) => {
    const timeInSeconds = getSecondsFromMinutesTimestamp(timeLabel);
    const dataIndex = dataSecondsArray.indexOf(timeInSeconds);

    let value = null;
    if (dataIndex !== -1 && dataArray[dataIndex] != null) {
      value = dataArray[dataIndex].toFixed(2);
    }

    if (value !== null) {
      results.push({ x: timeLabel, y: value });
    } else {
      results.push({ x: timeLabel, y: null });
    }
  });

  const updatedData = results.map((point) => {
    const seconds = this.getSecondsFromMinutesTimestamp(point.x);
    return [seconds, point.y ? +point.y : null];
  });
  const dataAfterFilter = largestTriangleThreeBuckets(updatedData, threshold);

  const finalData = dataAfterFilter.map((point) => {
    const minutes = Math.floor(point[0] / 60);
    const seconds = point[0] % 60;
    return {
      x: `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`,
      y: point[1],
    };
  });

  return finalData;
}

function largestTriangleThreeBuckets(data, threshold) {
  if (!+threshold) return data; // Check if threshold is a number
  if (!data.length || data.length < 200 || threshold < 2) return data; // Check for correctness of data and decimation requirements

  const buckets = threshold;
  const sampled = new Array(buckets);
  let a = 0; // Start from the first point

  sampled[0] = data[0]; // Always include the first point
  sampled[buckets - 1] = data[data.length - 1]; // Always include the last point

  const every = (data.length - 2) / (buckets - 2); // Divide the data into equal intervals
  let max_area, area, next_a;

  for (let i = 0; i < buckets - 2; i++) {
    const avg_range_start = Math.floor((i + 1) * every) + 1;
    let avg_range_end = Math.floor((i + 2) * every) + 1;
    avg_range_end = Math.min(avg_range_end, data.length - 1);

    const avg = { x: 0, y: 0 };

    for (let j = avg_range_start; j < avg_range_end; j++) {
      avg.x += data[j][0];
      avg.y += data[j][1];
    }
    const avg_len = avg_range_end - avg_range_start;
    avg.x /= avg_len;
    avg.y /= avg_len;

    max_area = area = -1;
    const range_start = Math.floor(i * every) + 1;
    const range_end = Math.floor((i + 1) * every) + 1;

    for (let k = range_start; k < range_end; k++) {
      // Area of a triangle
      area =
        Math.abs(
          (data[a][0] - avg.x) * (data[k][1] - data[a][1]) -
            (data[a][0] - data[k][0]) * (avg.y - data[a][1])
        ) / 2;
      if (area > max_area) {
        max_area = area;
        next_a = k;
      }
    }
    sampled[i + 1] = data[next_a];
    a = next_a;
  }

  return sampled;
}

// TODO remove it when Gilberto make update for HR dataset
function generateInterpolatedDataByTrainingTime(
  trainingTime,
  dataSecondsArray,
  dataArray,
  threshold
) {
  const result = [];
  const timeToSeconds = (timeStr) => {
    const [min, sec] = timeStr.split(":").map(Number);
    return min * 60 + sec;
  };

  trainingTime.forEach((trainingSecond) => {
    const currentSecond = timeToSeconds(trainingSecond);
    let exactMatchIndex = dataSecondsArray.indexOf(currentSecond);
    if (exactMatchIndex !== -1) {
      // If an exact time match is found
      result.push({
        x: trainingSecond,
        y: parseFloat(dataArray[exactMatchIndex].toFixed(2)),
      });
    } else {
      let prevIndex = -1;
      let nextIndex = -1;

      // Search for nearest indexes for interpolation
      for (let i = 0; i < dataSecondsArray.length; i++) {
        if (dataSecondsArray[i] < currentSecond) {
          prevIndex = i;
        } else if (dataSecondsArray[i] > currentSecond && nextIndex === -1) {
          nextIndex = i;
        }
      }

      if (prevIndex !== -1 && nextIndex !== -1) {
        // If indices for interpolation are found
        const prevTime = dataSecondsArray[prevIndex];
        const nextTime = dataSecondsArray[nextIndex];
        const prevValue = dataArray[prevIndex];
        const nextValue = dataArray[nextIndex];
        const slope = (nextValue - prevValue) / (nextTime - prevTime);
        const interpolatedValue =
          prevValue + slope * (currentSecond - prevTime);
        result.push({
          x: trainingSecond,
          y: parseFloat(interpolatedValue.toFixed(2)),
        });
      } else {
        // If there is no data for interpolation, write null
        result.push({
          x: trainingSecond,
          y: null,
        });
      }
    }
  });

  const updatedData = result.map((point) => {
    const seconds = this.getSecondsFromMinutesTimestamp(point.x);
    return [seconds, +point.y ? point.y : null];
  });
  const dataAfterFilter = largestTriangleThreeBuckets(updatedData, threshold);

  const finalData = dataAfterFilter.map((point) => {
    const minutes = Math.floor(point[0] / 60);
    const seconds = point[0] % 60;
    return {
      x: `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`,
      y: point[1],
    };
  });

  return finalData;
}

export {
  getSecondsFromMinutesTimestamp,
  getMinutesTimestampFromSeconds,
  getBackgroundColorByGait,
  setLocalZoomData,
  setScalesMinMax,
  generateZeroLine,
  generateDataByTrainingTime,
  generateInterpolatedDataByTrainingTime,
  largestTriangleThreeBuckets,
};
