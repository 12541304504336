<template>
  <div class="flex flex-col mb-3 text-[#5A5A5F] w-full">
    <label class="mb-2 text-sm">{{ label }}</label>
    <vue-timepicker
      close-on-complete
      hide-clear-button
      v-model="fieldData"
      class="h-[56px] w-full rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 placeholder:text-[#D9D9DF] text-md font-semibold"
      @change="handleChange"
    />
  </div>
</template>

<script>
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
export default {
  name: "TimePickerControl",
  components: {
    VueTimepicker,
  },
  props: {
    label: String,
    dataKey: String,
  },
  data() {
    return {
      fieldData: {HH: '', mm: ''},
    }
  },
  mounted() {
    const time = new Date()
    this.fieldData.HH =
      this.dataKey === 'startTime' ?
        time.getHours() < 10 ? '0' + time.getHours().toString() : time.getHours().toString()
        : (time.getHours() + 1) < 10 ? '0' + (time.getHours() + 1).toString() : (time.getHours() + 1).toString()
    this.fieldData.mm = '00'
  },
  methods: {
    handleChange() {
      this.$emit('update', {key: this.dataKey, data: this.fieldData})
    },
  },
}
</script>
<style>
.vue__time-picker input.vue__time-picker-input {
  width: 100%!important;
  height: 54px!important;
  border: none!important;
  border-radius: 0.25rem;
  padding: 8px 12px;
  font-weight: 500;
  font-family: 'Poppins', 'sans-serif';
}
.vue__time-picker .dropdown {
  top: calc(2.2em + 22px);
}
</style>