<template>
  <ModalComponent
    medium
    :is-open="termsModalOpen"
    :isConfirmDisabled="!confirmTermsCheck"
    @handleCancel="handleCancel"
    @handleAccept="handleAccept"
  >
    <template v-slot:content>
      <div class="h-[50vh] overflow-auto p-2 text-[#5A5A5F] text-lg">
        <div class="text-center mb-4 tracking-widest">
          <strong>Privacy Policy</strong>
        </div>

        <p class="my-2">This “Privacy Policy” (hereinafter referred to as the “Policy”) provides information and rules
          about:</p>

        <ul class="list-disc list-inside text-black my-2">
          <li>what data is collected;</li>
          <li>the reasons for collecting the data;</li>
          <li>the rules on the use of the data collected;</li>
        </ul>

        <p class="my-2">This is important information and we hope you will read it carefully.
          Personal data within the meaning of this Policy is any information that allows us to identify you, including
          information about your activities. For example, your use of certain Services/Sites, etc., if such use is
          directly related to personally identifiable information.
          Information about you (the Internet user) is collected solely in our shop during the Ordering process.
        </p>

        <div class="text-center mb-4 tracking-widest">
          <strong>What we collect and store</strong>
        </div>

        <p class="my-2">While you are visiting our website, we keep track of:</p>

        <ul class="list-disc list-inside text-black my-2">
          <li>Items you have browsed: we use this information to, for example, show you items you have recently
            browsed;
          </li>
          <li>Location, browser type: we use this for purposes such as calculating taxes and shipping;</li>
          <li>Shipping address: we’ll ask you to enter it so that we can, for example, estimate shipping costs before
            you place your order and send you the order!;
          </li>
          <li>We also use cookies to track the contents of your shopping basket while you are browsing our website;</li>
        </ul>

        <p class="my-2">When you make a purchase from us, we will ask you to provide the following information:</p>

        <p class="my-2">– Your name, billing address, delivery address, email address, phone number, credit card/payment
          details and optional account information such as username and password.</p>

        <p class="my-2">The information will be used by us for the following purposes:</p>

        <ul class="list-disc list-inside text-black my-2">
          <li>to send you information about your account and order;</li>
          <li>respond to your enquiries, including refunds and complaints;</li>
          <li>process payments and prevent fraud;</li>
          <li>set up your account with us;</li>
          <li>comply with any legal obligations, such as calculating taxes;</li>
          <li>improve the offerings of our shop;</li>
          <li>send you marketing messages if you wish to receive them;</li>
        </ul>

        <p class="my-2">If you create an account, we will store your name, address, email and phone number which will be
          used for future orders.
          We generally retain information about you for as long as we need it for the purposes for which we collect and
          use it, and we are not legally required to continue to retain it. For example, we will hold order information
          for 3 years for tax and accounting purposes. This includes your name, email address and billing and delivery
          addresses.
          We will also retain comments or feedback if you choose to leave them.
        </p>

        <div class="text-center mb-4 tracking-widest">
          <strong>Who on our team has access</strong>
        </div>

        <p class="my-2">Some members of our team will have access to the information you provide to us, such as shop
          administrators and managers:</p>

        <ul class="list-disc list-inside text-black my-2">
          <li>order information, for example: what was bought, when it was bought and where it is to be sent;</li>
          <li>customer information: such as your name, email address, billing and shipping information;</li>
        </ul>

        <p class="my-2">Our staff need access to this information to help fulfil orders, process returns and support
          you.</p>

        <div class="text-center mb-4 tracking-widest">
          <strong>What we share with other people</strong>
        </div>

        <p class="my-2">We share information with third parties who help us provide you with our orders and shop
          services; for example, our delivery service. With your consent we share such data as your name, delivery
          address, phone number..</p>

        <div class="text-center mb-4 tracking-widest">
          <strong>Payments</strong>
        </div>

        <p class="my-2">We accept payments via PayPal. When processing payments, some of your information will be shared
          with PayPal, including information needed to process or support the payment, such as purchase amount and
          billing information.
          Please see PayPal’s Privacy Policy for more information.
          We also accept payments through Strype. When processing payments, some of your information will be shared with
          Strype, including information necessary to process or support the payment, such as purchase amount and billing
          information.
          Please see Strype’s Privacy Policy for more information.
        </p>

        <div class="text-center mb-4 tracking-widest">
          <strong>We collect information about you when you install and use the HorseCare APP</strong>
        </div>

        <p class="my-2">By using the HorseCare mobile app, you are trusting us with your information.
          By using the HorseCare mobile application, you agree and accept its requirements/norms and give your consent
          to the collection, recording, storage, adaptation, modification, updating, use and distribution,
          depersonalization, destruction of personal data, including using information systems for the purposes
          specified in this Privacy Policy. The processing of your personal data starts from the moment you use the
          HorseCare mobile app.
          We reserve the right to modify this Privacy Policy at any time without personal communication to each user.
          Instead, we will publish the time of the most recent changes made to the Privacy Policy in the “Last
          Modification Date” field. By continuing to use the HorseCare mobile app after making such changes, you agree
          to and accept them as part of the Privacy Policy.
        </p>

        <div class="text-center mb-4 tracking-widest">
          <strong>Collecting personal data</strong>
        </div>

        <p class="my-2">Our HorseCare mobile app is very versatile and it is therefore sometimes necessary to apply
          additional categories of personal data used by the HorseCare mobile app.
          We may collect the following categories of personal data in order to provide services:
          Android version 6.0 and above requires location to scan BLE devices.
          This app does not use or transmit user location data anywhere!
          Automatic collection of technical information. When you use the HorseCare mobile app we automatically collect
          some information such as location data, your operating system type, and the time and date of your horse
          training, such information may be personal data if it directly identifies you.
          We may collect the following categories of personal data in order to provide our services:
        </p>

        <ul class="list-disc list-inside text-black my-2">
          <li>the unique identifier of the device, the IP address of the device;</li>
          <li>the version of the operating system of the device;</li>
        </ul>

        <p class="my-2">The user of the HorseCare mobile application has the right to:</p>

        <ul class="list-disc list-inside text-black my-2">
          <li>to deactivate their action and delete them at their own discretion;</li>
          <li>use of personal data;</li>
        </ul>

        <p class="my-2">We use all the information collected to provide, maintain, protect and improve the existing
          HorseCare mobile application and develop new services of the HorseCare mobile application.
          We use your personal data to:
        </p>

        <ul class="list-disc list-inside text-black my-2">
          <li>providing services using the HorseCare mobile app;</li>
          <li>facilitating your use of the HorseCare mobile app;</li>
        </ul>

        <p class="my-2">We use your personal data to the extent necessary for the rational execution of our legal obligations.</p>

        <div class="text-center mb-4 tracking-widest">
          <strong>Dissemination and disclosure of personal data</strong>
        </div>

        <p class="my-2">We do not share your personal data with third parties.</p>

        <div class="text-center mb-4 tracking-widest">
          <strong>Protection of your information</strong>
        </div>

        <p class="my-2">We recommend you to protect your privacy, in particular by safeguarding your profile passwords.</p>

        <div class="text-center mb-4 tracking-widest">
          <strong>How long will your personal data be stored?</strong>
        </div>

        <p class="my-2">Your personal data will be stored for as long as necessary to fulfil the above-mentioned purpose of processing such data, but no less than 3 years.
          Personal data can be destroyed after the expiry of the storage period or in cases prescribed by law.</p>

        <div class="text-center mb-4 tracking-widest">
          <strong>Feedback</strong>
        </div>

        <p class="my-2">We are always pleased to hear your thoughts or suggestions about improving our work as well as about the provisions of these Terms of Use.</p>

        <p class="my-2">Contact us by email: support@horsecare.si</p>

      </div>
    </template>
    <template v-slot:accept>
      <CheckboxControl
        dataKey="terms"
        id="modalAccept"
        :value="confirmTermsCheck"
        @update="onFieldChange"
      >
        <span class="text-[18px] text-[#9A8053] font-normal">
          {{$t('auth.I have read and accept the privacy policy displayed above')}}
        </span>
      </CheckboxControl>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent";
import CheckboxControl from "@/components/UI/CheckboxControl";

export default {
  name: "PrivacyPolicyComponent",
  components: {
    ModalComponent,
    CheckboxControl,
  },
  props: {
    isModalOpen: Boolean,
    confirmTermsCheck: Boolean,
  },
  data() {
    return {
      termsModalOpen: false,
    }
  },
  watch: {
    isModalOpen() {
      this.termsModalOpen = this.isModalOpen
    },
  },
  methods: {
    handleCancel() {
      this.closeModal()
      this.onFieldChange({key: 'terms', data: false})
    },
    handleAccept() {
      this.closeModal()
    },
    closeModal() {
      this.termsModalOpen = false
      this.$emit('emitCloseModal')
    },
    onFieldChange(val) {
      this.$emit('emitTerms', val)
    },
  },
}
</script>