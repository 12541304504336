import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegistrationView from "../views/auth/RegistrationView.vue";
import LoginView from "@/views/auth/LoginView";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView";
import BlockedAccountView from "@/views/auth/BlockedAccountView";
import HorsesView from "@/views/HorsesView";
import TaskboardView from "@/views/TaskboardView";
import TrainingView from "@/views/training/TrainingView";
import TrackingView from "@/views/training/TrackingView.vue";
import TrainingsComparisonView from "@/views/training/TrainingsComparisonView";
import CenterOfGravityTrackingView from "@/views/training/CenterOfGravityTrackingView.vue";
import routeNames from "@/router/routes";
import ErrorPageView from "@/views/ErrorPageView.vue";

const routes = [
  {
    path: "/",
    name: routeNames.home,
    component: HomeView,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      next({ path: `/${routeNames.horses}/0` });
    },
  },
  {
    path: "/registration",
    name: routeNames.registration,
    component: RegistrationView,
  },
  {
    path: "/login",
    name: routeNames.login,
    component: LoginView,
  },
  {
    path: "/forgot-password",
    name: routeNames.forgotPassword,
    component: ForgotPasswordView,
  },
  {
    path: "/account-blocked",
    name: routeNames.blockedAccount,
    component: BlockedAccountView,
  },
  {
    path: "/horses/:page",
    name: routeNames.horses,
    component: HorsesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/:page/horse/:id/training/:trainingId?",
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: routeNames.training,
        component: TrainingView,
      },
      {
        name: "tracking",
        component: TrackingView,
        path: routeNames.tracking,
      },
      {
        name: "center-of-gravity-tracking",
        component: CenterOfGravityTrackingView,
        path: routeNames.centerOfGravityTracking,
      },
      {
        name: "comparison",
        component: TrainingsComparisonView,
        path: routeNames.comparison,
      },
    ],
  },
  {
    path: "/taskboard",
    name: routeNames.taskboard,
    component: TaskboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routeNames.errorPage,
    component: ErrorPageView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  const routerAuthCheck = to.matched.some((route) => route.meta.requiresAuth);
  const accessToken = JSON.parse(
    localStorage.getItem("horsecare-userTokens")
  )?.refreshToken;

  if (routerAuthCheck) {
    if (accessToken) next();
    else next({ name: routeNames.login });
  } else if (!routerAuthCheck) {
    if (!accessToken) next();
    else next({ path: `/${routeNames.horses}/0` });
  }
});

export default router;
