<template>
  <div
    class="flex flex-col items-center justify-center mx-1"
    :style="{
      zIndex: overflowIndex,
    }"
  >
    <span class="text-[#5A5A5F] text-[21px] font-semibold text-center">
      {{ type }}
    </span>
    <div
      class="w-[10px] lg:w-[20px] h-[100px] bg-white drop-shadow-md rounded-md flex flex-col justify-end border-2"
    >
      <template v-if="isLamenessDataExist">
        <div
          v-if="pawIndex >= thresholds.danger"
          class="w-full h-[35%] bg-[#DC0E0E] rounded-t-md"
        ></div>
        <div
          v-if="pawIndex >= thresholds.warning"
          class="w-full h-[35%] bg-yellow-200"
        ></div>
        <div class="w-full h-[35%] bg-[#04D532] rounded-b-md"></div>
      </template>

      <template v-else>
        <div
          class="w-full h-full bg-gray-200 rounded-b-md cursor-pointer flex items-center justify-center"
          data-tooltip-target="tooltip"
          data-tooltip-style="light"
          data-tooltip-placement="right"
        >
          <InformationCircleIcon class="w-full h-[30px] text-gray-800" />
        </div>
        <div
          id="tooltip"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip"
        >
          {{ $t("training.Insufficient data for analysis") }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { initFlowbite } from "flowbite";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

export default {
  name: "PawSignatureComponent",
  components: {
    InformationCircleIcon,
  },
  props: {
    overflowIndex: Number,
    type: String,
    level: Number,
    thresholds: Object,
  },
  computed: {
    ...mapGetters(["reports"]),
    isLamenessDataExist() {
      switch (this.type) {
        case "W":
          return this.checkDataExist(this.reports.LAMENESS_REPORT?.walk);
        case "T":
          return this.checkDataExist(this.reports.LAMENESS_REPORT?.trot);
        case "G":
          return this.checkDataExist(this.reports.LAMENESS_REPORT?.gallop);
        default:
          return false;
      }
    },
    pawIndex() {
      return this.level ? this.level * 100 : 0;
    },
  },
  methods: {
    checkDataExist(obj) {
      return obj ? Object.values(obj).every((value) => value !== null) : false;
    },
  },
  mounted() {
    initFlowbite();
  },
};
</script>
