<template>
  <div
    class="w-auto bg-[#9a80530d] drop-shadow-md rounded-lg px-2 py-4 mx-3 mb-4 flex flex-col items-center justify-center"
  >
    <p
      class="font-poppins text-[#5A5A5F] text-[18px] text-center sm:text-start mb-2"
    >
      {{ $t("charts-selector.Select charts to show") }}
    </p>
    <div class="w-full flex flex-col flex-wrap justify-center items-center">
      <div class="w-full flex flex-col justify-start items-start">
        <div v-for="(i, index) of currentChartList" :key="index" class="my-1">
          <input
            :id="i.title"
            type="checkbox"
            class="w-[28px] h-[28px] appearance-none focus:ring-0 focus:ring-offset-0 checked:text-[#9A8053] mr-2 cursor-pointer"
            v-model="i.isEnabled"
          />
          <label
            :for="i.title"
            class="text-[18px] text-[#9A8053] font-poppins cursor-pointer"
          >
            {{ $t(i.title) }}
          </label>
        </div>
      </div>
    </div>
    <div class="w-full">
      <Button @click="updateChartsList">{{
        $t("charts-selector.Apply")
      }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ChartsSelectorComponent",
  components: {
    Button,
  },
  props: {
    availableChartsList: Array,
  },
  data() {
    return {
      selectedCharts: [],
    };
  },
  watch: {
    selectedCharts(newData) {
      this.availableChartsList.map((i) => {
        newData.includes(i.data) ? (i.isEnabled = true) : (i.isEnabled = false);
      });
    },
  },
  mounted() {
    this.selectSavedCharts();
  },
  computed: {
    ...mapGetters(["selectedChartsList"]),
    currentChartList() {
      switch (this.$route.name) {
        case "comparison":
          return this.availableChartsList.filter(
            (i) => i.type === "comparison"
          );

        case "tracking":
          return this.availableChartsList.filter((i) => i.type === "tracking");

        case "training":
          return this.availableChartsList.filter((i) => i.type === "training");

        default:
          return this.availableChartsList;
      }
    },
  },
  methods: {
    ...mapMutations(["UPDATE_SELECTED_CHARTS_LIST"]),
    selectSavedCharts() {
      const data = localStorage.getItem("selected-charts")
        ? JSON.parse(localStorage.getItem("selected-charts"))
        : {};
      const currentData = data[this.$route.params.id];

      this.UPDATE_SELECTED_CHARTS_LIST(currentData ? currentData : []);
      this.selectedCharts = currentData ? currentData : [];
    },
    saveSelectedCharts(charts) {
      const data = localStorage.getItem("selected-charts")
        ? JSON.parse(localStorage.getItem("selected-charts"))
        : {};
      data[this.$route.params.id] = charts;
      localStorage.setItem("selected-charts", JSON.stringify(data));
    },
    updateChartsList() {
      const chartsList = this.availableChartsList
        .filter((i) => i.isEnabled)
        .map((i) => i.data);
      this.saveSelectedCharts(chartsList);
      this.UPDATE_SELECTED_CHARTS_LIST(chartsList);
    },
  },
};
</script>
