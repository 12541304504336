<template>
  <div class="inline-block sm:w-[522px] mx-2 my-2 sm:m-2">
    <div
      class="bg-white max-w-[450px] min-h-[220px] rounded-[20px] drop-shadow-md p-4 mx-auto flex items-center justify-center flex-col-reverse sm:flex-row"
      :class="{
        'cursor-pointer hover:drop-shadow-3xl hover:bg-gray-200': !dashboard,
        relative: dashboard,
      }"
      @click.stop="handleToTrainingPage()"
    >
      <div class="w-full p-1 flex flex-col justify-center">
        <div class="flex flex-wrap justify-between w-full mb-2">
          <div>
            <div class="flex justifystart items-center">
              <img
                :src="require('@/assets/avatar.svg')"
                class="w-12 h-12 mr-2 text-white"
                alt="avatar"
              />
              <div class="flex flex-col ml-2">
                <div class="mb-2 text-[#5A5A5F] font-semibold text-[21px]">
                  {{ horse.name }}
                </div>
                <div
                  v-if="!horse.shared"
                  class="flex justify-start items-center"
                >
                  <div class="text-[#5A5A5F] text-[18px] font-semibold">
                    {{ $t("horses-list.Favourite") }}:
                  </div>
                  <svg
                    v-if="horse.favorite"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="ml-2 w-6 h-6 text-red-500"
                  >
                    <path
                      d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="ml-2 w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    />
                  </svg>
                </div>
                <div v-else class="text-[#5A5A5F] text-[18px] font-semibold">
                  Shared horse
                </div>
              </div>
            </div>
          </div>
          <svg
            v-if="!horse.shared"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-[24px] h-[24px] cursor-pointer"
            :class="{ 'mt-1': !dashboard, 'absolute top-4 right-4': dashboard }"
            @click.stop="handleOpenModal('horseInfo')"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </div>
        <div class="w-full mt-4 flex flex-wrap justify-start items-center">
          <div class="text-[#5A5A5F] text-[18px] font-semibold mr-1">
            {{ $t("horses-list.Disciplines") }}:
          </div>
          <div
            v-for="i of horse.disciplines"
            :key="i"
            class="text-[#A3A3A3] text-[15px] mx-1"
          >
            {{ i }}
          </div>
        </div>
      </div>
      <div class="w-[280px] h-[180px] mb-4 md:mb-0">
        <img
          :src="setAvatarUrl(horse.avatarUrl)"
          class="rounded-full shadow-xl"
          :class="{
            'w-full h-full object-cover mt-1': !dashboard,
            'w-[180px] h-[180px] my-auto': dashboard,
          }"
          alt="avatar"
        />
      </div>
    </div>
  </div>
  <AddHorseComponent
    update
    :isModalOpen="horseInfoModal"
    :horseInfo="horse"
    @closeModal="handleCancel('horseInfo')"
  />
</template>

<script>
import AddHorseComponent from "@/components/horsesList/AddHorseComponent";
import { mapActions, mapGetters, mapMutations } from "vuex";
import routeNames from "@/router/routes";

export default {
  name: "HorseComponent",
  components: {
    AddHorseComponent,
  },
  props: {
    horse: Object,
    dashboard: Boolean,
  },
  data() {
    return {
      routeNames,
      trainingHistoryModal: false,
      horseInfoModal: false,
      training: null,
    };
  },
  computed: {
    ...mapGetters(["trainingDates", "selectedDayTrainings"]),
  },
  methods: {
    ...mapActions([
      "getHorseTrainingHistory",
      "getTrainingsDates",
      "getSelectedDayTrainingsData",
    ]),
    ...mapMutations(["SET_LOADING", "SET_SELECTED_TRAINING_TIME_DATA"]),
    async getSelectedDayTrainings(date) {
      const data = {
        id: this.horse.id,
        fromDate: date,
        toDate: date,
      };
      await this.getSelectedDayTrainingsData(data);
    },
    changeTrainingData() {
      if (this.training?.results) {
        const data = {
          results: this.training.results,
          id: this.training.trainingId,
          type: this.type,
          date: this.trainingDate,
          time: this.training,
        };
        this.SET_SELECTED_TRAINING_TIME_DATA(this.training);
        this.$emit("changeTraining", data);
      } else {
        this.SET_LOADING(false);
      }
    },
    async setSelectedDayTrainings() {
      const date = this.selectedTrainingDate
        ? this.selectedTrainingDate
        : this.trainingDates[this.trainingDates.length - 1];
      await this.getSelectedDayTrainings(date);
      if (this.selectedDayTrainings) {
        this.training = this.selectedDayTrainings[0];
        this.changeTrainingData();
      }
    },
    setAvatarUrl(url) {
      if (url && url.includes("https://")) return url;
      else return require("@/assets/horse.png");
    },
    async handleToTrainingPage() {
      this.SET_LOADING(true);
      await this.getTrainingsDates(this.horse.id);
      await this.setSelectedDayTrainings();

      this.$router.push({
        name: this.routeNames.training,
        params: {
          page: this.$route.params.page,
          id: this.horse.id,
          trainingId: this.training?.trainingId ?? "",
        },
      });
    },
    handleOpenModal(type) {
      if (type === "trainingHistory") {
        this.getHorseTrainingHistory(this.horse.id);
        this.trainingHistoryModal = true;
      } else if (type === "horseInfo") {
        this.horseInfoModal = true;
      }
    },
    handleCancel(type) {
      if (type === "trainingHistory") {
        this.trainingHistoryModal = false;
      } else if (type === "horseInfo") {
        this.horseInfoModal = false;
      }
    },
  },
};
</script>
