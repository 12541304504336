<template>
  <ModalComponent
    small
    :is-open="isModalOpen"
    :is-confirm-disabled="isConfirmDisabled"
    @handleCancel="handleCancel"
    @handleAccept="handleAccept"
  >
    <template v-slot:title>
      <div class="w-full">Create a new event in calendar</div>
    </template>
    <template v-slot:content>
      <div>
        <SelectControl
          label="Select horse"
          dataKey="horseName"
          :options="horsesOptions"
          @update="onFieldChange"
        />
        <DatePickerControl
          label="Select date"
          dataKey="date"
          @update="onFieldChange"
        />
        <TimePickerControl
          label="Select start time"
          dataKey="startTime"
          @update="onFieldChange"
        />
        <TimePickerControl
          label="Select end time"
          dataKey="endTime"
          @update="onFieldChange"
        />
        <TextareaControl
          label="Description"
          dataKey="description"
          @update="onFieldChange"
        />
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent";
import SelectControl from "@/components/UI/SelectControl";
import DatePickerControl from "@/components/UI/DatePickerControl";
import TimePickerControl from "@/components/UI/TimePickerControl";
import TextareaControl from "@/components/UI/TextareaControl";

import {mapActions, mapGetters} from "vuex";

export default {
  name: "AddEventComponent",
  components: {
    ModalComponent,
    SelectControl,
    DatePickerControl,
    TimePickerControl,
    TextareaControl,
  },
  props: {
    isModalOpen: Boolean,
  },
  data() {
    return {
      isConfirmDisabled: false,
      eventData: {
        horseName: '',
        date: '',
        startTime: {HH: '', mm: ''},
        endTime: {HH: '', mm: ''},
        description: '',
      }
    }
  },
  computed: {
    ...mapGetters(['horsesList']),
    horsesOptions() {
      return this.horsesList.map( i => i.name)
    },
  },
  methods: {
    ...mapActions(['addEvent']),
    onFieldChange(val) {
      this.eventData[val.key] = val.data
    },
    handleCancel() {
      this.$emit('closeModal')
    },
    handleAccept() {
      this.addNewEvent()
      this.$emit('closeModal')
    },
    addNewEvent() {
      this.addEvent({
        horseName: this.eventData.horseName,
        date: new Date(this.eventData.date),
        startTime: this.eventData.startTime,
        endTime: this.eventData.endTime,
        description: this.eventData.description,
        id: new Date().getTime(),
      })
    }
  },
}
</script>