import { useToast } from "vue-toastification";
const toast = useToast();

function showErrorAndGoToHomePage(errorMessage) {
  toast.error(errorMessage);
  // Timeout for toast msg and go to homepage with reloading to avoid errors
  setTimeout(() => {
    window.location.href = "/";
  }, 2000);
}

export { showErrorAndGoToHomePage };
