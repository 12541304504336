<template>
  <div v-if="loading" class="w-full h-screen flex justify-center items-center">
    <LoaderComponent />
  </div>
  <div v-else class="self-start w-full h-full flex flex-col justify-start items-center">
    <div class="w-full flex flex-wrap justify-center mt-8 lg:mt-0 md:justify-between items-center">
      <div class="flex items-center justify-center md:justify-start flex-wrap">
        <button
          class="rounded-md px-3 py-2 text-sm font-semibold text-[#9A8053] flex items-center"
          @click="addHorseModal = true"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
               class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>

          <span class="ml-2">{{$t('horses-list.Add new horse')}}</span>
        </button>
      </div>


      <nav v-if="horsesList && horsesList.length" class="flex items-center justify-between border border-gray-200 px-4 py-3 sm:px-6" aria-label="Pagination">
        <div class="hidden sm:block">
          <p class="text-sm text-[#9A8053] mr-2">
            {{$t('horses-list.Showing')}}
            <span class="font-medium">{{startItems}}</span>
            -
            <span class="font-medium">{{endItems}}</span>
            {{$t('horses-list.of')}}
            <span class="font-medium">{{totalElements}}</span>
            {{$t('horses-list.horses')}}
          </p>
        </div>
        <div class="flex flex-1 justify-between sm:justify-end">
          <button
            :disabled="startItems === 1"
            class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            :class="{ 'text-gray-200': startItems === 1, 'text-gray-600': startItems !== 1}"
            @click="prevPage"
          >
            {{$t('horses-list.Previous')}}
          </button>
          <button
            :disabled="endItems === totalElements"
            class="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            :class="{ 'text-gray-200': endItems === totalElements, 'text-gray-600': endItems !== totalElements}"
            @click="nextPage"
          >
            {{$t('horses-list.Next')}}
          </button>
        </div>
      </nav>
    </div>
    <div class="w-auto max-w-[1655px] mx-auto flex  2xl:flex-3 justify-center 2xl:justify-start flex-wrap">
      <HorseComponent
        v-for="(horse, index) of sortedHorsesList"
        :key="index"
        :horse="horse"
      />
    </div>
  </div>
  <AddHorseComponent
    :isModalOpen="addHorseModal"
    @closeModal="addHorseModal = false"
  />
</template>

<script>
import HorseComponent from "@/components/horsesList/HorseComponent";
import {mapActions, mapGetters, mapMutations} from "vuex";
import AddHorseComponent from "@/components/horsesList/AddHorseComponent";
import LoaderComponent from "@/components/LoaderComponent";

export default {
  name: "HorsesListComponent",
  components: {
    HorseComponent,
    AddHorseComponent,
    LoaderComponent,
  },
  data() {
    return {
     addHorseModal: false,
      startItems: 1,
      page: 0,
    }
  },
  watch: {
    horsesList() {
      if(this.horsesList.length === 0 && Number(this.$route.params.page) > 0) {
        this.prevPage()
      }
    },
  },
  computed: {
    ...mapGetters(['horsesList', 'loading', 'totalElements']),
    sortedHorsesList() {
      const horses = this.horsesList
      if(horses) return horses.sort((a, b) => Number(b.favorite) - Number(a.favorite));
      else return []
    },
    endItems() {
      if (!this.horsesList) return 20
      const page = Number(this.$route.params.page)
      return this.startItems + (this.startItems === 1 ? 19 : 20) < (page * 20) + this.horsesList.length ? this.startItems + (this.startItems === 1 ? 19 : 20) : (page * 20) + this.horsesList.length
    },
  },
  mounted() {
    this.setStartItems(Number(this.$route.params.page))
  },
  methods: {
    ...mapActions(['getHorsesList']),
    ...mapMutations(['SET_LOADING']),
    setStartItems(page) {
      this.startItems = (page * 20) + 1
    },
    async nextPage() {
      this.endItems < this.horsesList.length ? this.startItems = this.endItems : null
      const page = Number(this.$route.params.page) + 1
      this.$router.push({name: 'horses', params: {page: page}})
      this.setStartItems(page)
      this.SET_LOADING(true)
      await this.getHorsesList(page)
      this.SET_LOADING(false)
    },
    async prevPage() {
      this.startItems = this.startItems === 10 ? this.startItems - 9 : this.startItems - 10
      const page = Number(this.$route.params.page) - 1
      this.$router.push({name: 'horses', params: {page: page}})
      this.setStartItems(page)
      this.SET_LOADING(true)
      await this.getHorsesList(page)
      this.SET_LOADING(false)
    },
  },
}
</script>