import {calendarEvents} from "@/data/calendarEvents";
import { useToast } from 'vue-toastification';
import { mock } from '@/utils/mockPromice';

const toast = useToast()

const state = {
  calendarEvents: null
}

const getters = {
  calendarEvents: state => state.calendarEvents,
}

const mutations = {
  SET_EVENTS(state, data) {
    state.calendarEvents = data
  },
  ADD_EVENT(state, data) {
    state.calendarEvents.push(data)
  },
  DELETE_EVENT(state, id) {
    state.calendarEvents = state.calendarEvents.filter( i => i.id !== id)
  },
}

const actions = {
  async getEvents({commit}) {
    return await mock(true, 2000, calendarEvents)
      .then(data => {
        commit('SET_EVENTS', data)
      })
      .catch(err => {
        toast.error(err.message)
        throw err
      })
  },
  addEvent({commit}, data) {
    commit('ADD_EVENT', data)
  },
  deleteEvent({commit}, id) {
    commit('DELETE_EVENT', id)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
