<template>
  <Line
    class="w-full max-h-[400px] xl:h-auto"
    :data="chartData"
    :options="options"
    ref="chart"
  />
</template>
<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "vue-chartjs";
import { format } from "date-fns";
import annotationPlugin from "chartjs-plugin-annotation";
import { mapGetters } from "vuex";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  TimeScale,
  annotationPlugin
);
export default {
  name: "EnergyChartComponent",
  components: { Line },
  props: {
    indexColor: String,
    datasets: Array,
    cutValue: Array,
  },
  data() {
    return {
      chartData: {
        datasets: [],
      },
      FLdata: 0,
      FRdata: 0,
      BLdata: 0,
      BRdata: 0,
    };
  },
  watch: {
    cutValue() {
      this.setDataProperties();
    },
  },
  mounted() {
    this.setDataProperties();
  },
  computed: {
    ...mapGetters(["currentTrackIndex"]),
    trainingStartTS() {
      const date = new Date(2011, 0, 1, 0, 0, 0, 0);
      return Date.parse(date);
    },
    annotation() {
      return {
        type: "line",
        borderColor: this.indexColor,
        borderWidth: 2,
        scaleID: "x",
        value: this.cutValue
          ? this.trainingStartTS +
            this.cutValue[0] * 200 +
            this.currentTrackIndex * 200
          : this.trainingStartTS + this.currentTrackIndex * 200,
      };
    },
    options() {
      return {
        normalized: true,
        animation: false,
        spanGaps: false,
        decimation: false,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
					crosshair: false,
          datalabels: false,
          annotation: {
            annotations: {
              annotation: this.annotation,
            },
          },
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                return format(tooltipItem[0].parsed.x, "HH:mm");
              },
              label: (el) => {
                return el.raw.y + " " + this.$t("training.kJ");
                // return this.$t('tracking.Type') + ': '  + this.$t('tracking.' +el.dataset.title) + ', ' + el.parsed.y.toFixed(0) + ' ' + this.$t('training.Kj') + ' ' + this.$t('tracking.Coast')
              },
            },
          },
          decimation: true,
          colors: {
            forceOverride: true,
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function (val) {
                return format(val, "HH:mm");
              },
            },
            type: "time",
            time: {
              displayFormats: {
                quarter: "MMM YYYY",
              },
            },
            barThickness: "1",
            maxBarThickness: 1,
          },
        },
      };
    },
  },
  methods: {
    setMappedSensorData(data) {
      return data.ts
        .map((i, index) => {
          return {
            ts: i.slice(0, 8),
            data: data.cumulative_energy[index] / 1000,
          };
        })
        .reduce(
          (a, c) => (a.map((e) => e.ts).includes(c.ts) || a.push(c), a),
          []
        );
    },
    setMappedAllSensorsData(data, type, color) {
      this.FLdata = 0;
      this.FRdata = 0;
      this.BLdata = 0;
      this.BRdata = 0;

      if (!data?.BACK) return;

      const back = this.setMappedSensorData(data.BACK);
      const FL = this.setMappedSensorData(data.FL);
      const FR = this.setMappedSensorData(data.FR);
      const BL = this.setMappedSensorData(data.BL);
      const BR = this.setMappedSensorData(data.BR);

      return {
        title: type,
        backgroundColor: color,
        borderColor: "white",
        pointRadius: 2,
        borderWidth: 0,
        data: this.setAllSensorsInCurrentTsData(back, FL, FR, BL, BR),
      };
    },
    setAllSensorsInCurrentTsData(back, FL, FR, BL, BR) {
      const res = [];

      back.map((i) => {
        if (this.setFlattenedData(i.ts, i.data, FL, FR, BL, BR)) {
          res.push({
            x: this.trainingStartTS + this.convertTimeToMs(i.ts) * 1000,
            y: this.setFlattenedData(i.ts, i.data, FL, FR, BL, BR),
          });
        }
      });
      const filteredRes = res.filter(
        (i, index) => index > 0 && i.x - 1000 === res[index - 1].x
      );

      if (this.cutValue) {
        const startMs = this.trainingStartTS + this.cutValue[0] * 200;
        const endTs = this.trainingStartTS + this.cutValue[1] * 200;

        return filteredRes.filter((i) => i.x > startMs && i.x < endTs);
      } else {
        return filteredRes;
      }
    },
    setFlattenedData(ts, data, FL, FR, BL, BR) {
      const FLdata = this.findPreviousValue("FLdata", FL, ts);
      const FRdata = this.findPreviousValue("FRdata", FR, ts);
      const BLdata = this.findPreviousValue("BLdata", BL, ts);
      const BRdata = this.findPreviousValue("BRdata", BR, ts);

      if (
        FLdata === 0 ||
        FRdata === 0 ||
        (BLdata === 0) | (BRdata === 0) | (data === 0)
      ) {
        return null;
      }
      return +(data + +FLdata + +FRdata + +BLdata + +BRdata).toFixed(0);
    },
    findPreviousValue(type, leg, ts) {
      if (leg.find((i) => i.ts === ts)) {
        const res = leg.find((i) => i.ts === ts).data;
        this[type] = res;
        return res;
      } else {
        return this[type];
      }
    },
    convertTimeToMs(str) {
      let times = str.split(":");
      times.reverse();
      let x = times.length,
        y = 0,
        z;
      for (let i = 0; i < x; i++) {
        z = times[i] * Math.pow(60, i);
        y += z;
      }
      return y;
    },
    setDataProperties() {
      this.chartData = {
        datasets: [
          this.setMappedAllSensorsData(this.datasets.walk, "Walk", "#1AB0B0"),
          this.setMappedAllSensorsData(this.datasets.trot, "Trot", "#FFCD4B"),
          this.setMappedAllSensorsData(
            this.datasets.gallop,
            "Gallop",
            "#F85C7F"
          ),
        ],
      };
    },
  },
};
</script>
