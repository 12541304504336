function getClass(val) {
  return `bg-[${val}]`;
}

function getSecond(secondTs) {
  const timeParts = secondTs.split(":").map(Number);
  return Math.round(
    timeParts[0] * 3600 +
      timeParts[1] * 60 +
      (timeParts.length >= 3 ? timeParts[2] : 0)
  );
}

function getSeconds(ts) {
  const secondsArray = [];

  ts.forEach((i) => {
    const second = this.getSecond(i);
    secondsArray.push(second);
  });

  return secondsArray;
}

function formatTimestamp(timestamp) {
  const parts = timestamp.split(":");

  // Extract hours, minutes, and seconds
  const hours = parts[0];
  const minutes = parts[1];
  const seconds = parts[2].split(".")[0]; // Remove milliseconds

  // Format the output based on whether hours are present or not
  if (hours !== "00") {
    return `${parseInt(hours)}:${minutes}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
}

export { getClass, getSecond, getSeconds, formatTimestamp };
