<template>
  <div class="text-[#5A5A5F]">
    <p class="text-[32px] font-medium mb-3">{{$t('auth.Create account')}}</p>
    <form
      @submit.prevent="onSubmit"
      class="w-full max-w-[352px]"
    >
      <p class="mb-2 text-sm text-end">*{{$t('auth.Mandatory Fields')}}</p>

      <InputControl
        type="email"
        label="auth.email label"
        placeholder="auth.email placeholder"
        dataKey="email"
        :error="!!v$.formData.email.$errors"
        :errorText="v$.formData.email.$errors[0]?.$message"
        @update="onFieldChange"
      />

      <InputControl
        type="password"
        label="auth.password label"
        placeholder="auth.password placeholder"
        dataKey="password"
        password
        :error="!!v$.formData.password.$errors"
        :errorText="v$.formData.password.$errors[0]?.$message"
        @update="onFieldChange"
      />

      <InputControl
        type="password"
        label="auth.confirm password label"
        placeholder="auth.password placeholder"
        dataKey="confirmPassword"
        password
        :error="!!v$.formData.confirmPassword.$errors"
        :errorText="v$.formData.confirmPassword.$errors[0]?.$message"
        @update="onFieldChange"
      />

      <CheckboxControl
        dataKey="terms"
        id="accept"
        @update="onFieldChange"
        :value="formData.terms"
      >
        <span class="tracking-wide">
          {{$t('auth.privacy policy')}}
        <span class="cursor-pointer text-[#9A8053] ml-1" @click.prevent="termsModal = true"> {{$t('auth.Terms & Conditions')}} </span>
        </span>
      </CheckboxControl>

      <Button :disabled="!formData.terms || loading" :loading="loading">{{$t('auth.Create account')}}</Button>

      <p class="my-3 text-sm">
        {{$t('auth.Already have an account')}}?
        <router-link
          :to="{name: 'login' }"
          class="cursor-pointer text-[#9A8053] ml-1"
        >
          {{$t('auth.Login now')}}
        </router-link>
      </p>
    </form>
    <SocialComponent
      @emittedSocial="registerWithSocial"
    />
  </div>
  <PrivacyPolicyComponent
    :isModalOpen="termsModal"
    :confirmTermsCheck="formData.terms"
    @emitTerms="onFieldChange"
    @emitCloseModal="termsModal = false"
  />
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import {required, helpers, email, sameAs} from '@vuelidate/validators'
import SocialComponent from "@/components/auth/SocialComponent";
import Button from "@/components/UI/Button";
import CheckboxControl from "@/components/UI/CheckboxControl";
import InputControl from "@/components/UI/InputControl";
import PrivacyPolicyComponent from "@/components/auth/PrivacyPolicyComponent";
import {mapActions, mapGetters, mapMutations} from "vuex";

const passRegex = value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#*$%])./.test(value)
export default {
  name: "RegistrationFormComponent",
  components: {
    SocialComponent,
    Button,
    CheckboxControl,
    InputControl,
    PrivacyPolicyComponent,
  },
  data() {
    return {
      v$: useVuelidate(),
      termsModal: false,
      formData: {
        email: "",
        password: "",
        confirmPassword: "",
        terms: false,
      },
    }
  },
  validations() {
    return {
      formData: {
        email: {
          required: helpers.withMessage(this.$t('This field is required'), required),
          email: helpers.withMessage(this.$t('This field must be a valid email'), email),
        },
        password: {
          required: helpers.withMessage(this.$t('This field is required'), required),
          passRegex: helpers.withMessage('Please enter in the specified format', passRegex),
        },
        confirmPassword: {
          // required: helpers.withMessage(this.$t('This field is required'), required),
          sameAs: helpers.withMessage(this.$t('Passwords do not match'), sameAs(this.formData.password)),
        },
      }
    }

  },
  computed: {
    ...mapGetters(['loading'])
  },
  methods: {
    ...mapActions(['registrationWithEmail', 'loginWithSocial']),
    ...mapMutations(['SET_LOADING',]),
    registerWithSocial(social) {
      this.loginWithSocial(social)
    },
    onSubmit() {
      this.v$.$validate()
      if (!this.v$.$error && this.formData.terms) {
        this.SET_LOADING(true)
        this.registrationWithEmail({email: this.formData.email, password: this.formData.password})
      }
    },
    onFieldChange(val) {
      this.formData[val.key] = val.data
    },
  },
}
</script>