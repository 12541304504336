<template>
  <div class="flex h-[calc(100vh_-_150px)] w-[100%] flex-col">
    <header class="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
      <h1 class="text-base font-semibold leading-6 text-gray-900">
        <time datetime="2022-01">{{ calendarTitle }}</time>
      </h1>
      <div class="flex items-center">
        <div class="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <div class="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
               aria-hidden="true"/>
          <button
            class="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            @click="setCalendarPrevWeek"
          >
            <span class="sr-only">Previous week</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true"/>
          </button>
          <button
            class="hidden px-3.5 text-sm font-semibold text-[#9A8053] hover:bg-gray-50 focus:relative md:block"
            @click="this.setCalendarWeek(new Date())"
          >
            Today
          </button>
          <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden"/>
          <button type="button"
                  class="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                  @click="setCalendarNextWeek"
          >
            <span class="sr-only">Next week</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true"/>
          </button>
        </div>
        <div class="hidden md:ml-4 md:flex md:items-center">
          <Menu as="div" class="relative">

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              </MenuItems>
            </transition>
          </Menu>
          <div class="ml-6 h-6 w-px bg-gray-300"/>
          <button
            class="ml-6 rounded-md px-3 py-2 text-sm font-semibold text-[#9A8053] flex items-center"
            @click="handleOpenModal('add')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>

            <span class="ml-2">Add new event</span>
          </button>
        </div>
        <Menu as="div" class="relative ml-6 md:hidden">
          <MenuButton
            class="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Open menu</span>
            <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true"/>
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a href="#"
                     :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Create
                    event</a>
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a href="#"
                     :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Go to
                    today</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </header>
    <div ref="container" class="isolate flex flex-auto flex-col overflow-auto bg-white">
      <div style="width: 165%" class="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
        <div ref="containerNav"
             class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
          <div class="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
            <button type="button" class="flex flex-col items-center pb-3 pt-2">
              M
              <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">10</span>
            </button>
            <button type="button" class="flex flex-col items-center pb-3 pt-2">
              T
              <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">11</span>
            </button>
            <button type="button" class="flex flex-col items-center pb-3 pt-2">
              W
              <span
                class="mt-1 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white">12</span>
            </button>
            <button type="button" class="flex flex-col items-center pb-3 pt-2">
              T
              <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">13</span>
            </button>
            <button type="button" class="flex flex-col items-center pb-3 pt-2">
              F
              <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">14</span>
            </button>
            <button type="button" class="flex flex-col items-center pb-3 pt-2">
              S
              <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">15</span>
            </button>
            <button type="button" class="flex flex-col items-center pb-3 pt-2">
              S
              <span class="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">16</span>
            </button>
          </div>

          <div
            class="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
            <div class="col-end-1 w-14"/>
            <div class="flex flex-col items-center justify-center py-3" v-for="(day, index) of week" :key="index">
              <span> {{ day.weekDay }} </span>
              <span class="flex items-center justify-center font-semibold text-gray-900">
                  <span class="flex h-6 w-6 mr-1 items-center justify-center rounded-full font-semibold"
                        :class="{'bg-blue-500 text-white': day.isTodayDate}"
                  >
                    {{ day.monthDate }}
                  </span>
                  {{ day.month }}
                </span>
            </div>
          </div>
        </div>
        <div class="flex flex-auto">
          <div class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100"/>
          <div class="grid flex-auto grid-cols-1 grid-rows-1">
            <!-- Horizontal lines -->
            <div class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                 style="grid-template-rows: repeat(48, minmax(3.5rem, 1fr))">
              <div ref="containerOffset" class="row-end-1 h-7"/>
              <template v-for="hour of hours" :key="hour">
                <div>
                  <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    {{ hour }}
                  </div>
                </div>
                <div/>
              </template>
            </div>

            <!-- Vertical lines -->
            <div
              class="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
              <div class="col-start-1 row-span-full"/>
              <div class="col-start-2 row-span-full"/>
              <div class="col-start-3 row-span-full"/>
              <div class="col-start-4 row-span-full"/>
              <div class="col-start-5 row-span-full"/>
              <div class="col-start-6 row-span-full"/>
              <div class="col-start-7 row-span-full"/>
              <div class="col-start-8 row-span-full w-8"/>
            </div>

            <!-- Events -->
            <ol v-if="filteredEventsList" class="col-start-1 col-end-2 row-start-1 grid grid-cols-7 pr-8"
                style="grid-template-rows: 1.75rem repeat(288, minmax(0, 1fr)) auto">
              <li
                v-for="(event, index) of filteredEventsList"
                :key="index"
                :class="event.colClass"
                class="relative mt-px hidden sm:flex cursor-pointer"
                :style="eventTime(event.startTime, event.endTime)"
                @click="handleDeleteEvent(event.id)"
              >
                <a
                  class="group absolute inset-1 flex justify-start overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100">
                  <div class="w-[6px] rounded-lg h-full bg-blue-500 -ml-1 mr-2">
                  </div>
                  <div class="h-full w-full flex flex-col items-start">
                    <div class="flex justify-between w-full">
                      <div>
                        <p class="font-semibold text-blue-700">{{ event.horseName }}</p>
                        <p class="text-blue-500 group-hover:text-blue-700">
                          <time datetime="2022-01-12T06:00">{{ event.startTime.HH }}:{{ event.startTime.mm }} -
                            {{ event.endTime.HH }}:{{ event.endTime.mm }}
                          </time>
                        </p>
                      </div>
                      <img :src="require('@/assets/horse.png')" class="rounded-full shadow-xl w-[40px] h-[40px]"/>
                    </div>
                    <p class="mt-2">{{ event.description }}</p>
                  </div>

                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddEventComponent
    :isModalOpen="addEventModal"
    @closeModal="handleCloseModal('add')"
  />
  <ModalComponent
    small
    :is-open="deleteEventModal"
    @handleCancel="handleCloseModal('delete')"
    @handleAccept="confirmDeleteEvent()"
  >
    <template v-slot:title>
      <span class="px-2">Сonfirm event deletion</span>
    </template>
    <template v-slot:content>
      <span class="text-[#5A5A5F] font-semibold px-2">
        Are you sure that you want to delete this event?
      </span>
    </template>
  </ModalComponent>
</template>

<script>
import {ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon} from '@heroicons/vue/20/solid'
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import AddEventComponent from "@/components/taskboard/AddEventComponent";
import {mapActions, mapGetters} from 'vuex'
import ModalComponent from "@/components/ModalComponent";

export default {
  name: "CalendarComponent",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    EllipsisHorizontalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    AddEventComponent,
    ModalComponent,
  },
  data() {
    return {
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      hours: ['00 H', '1 H', '2 H', '3 H', '4 H', '5 H', '6 H', '7 H', '8 H', '9 H', '10 H', '11 H', '12 H', '13 H', '14 H', '15 H', '16 H', '17 H', '18 H', '19 H', '20 H', '21 H', '22 H', '23 H'],
      week: [],
      addEventModal: false,
      deleteEventModal: false,
      id: null,
    }
  },
  mounted() {
    this.setCalendarWeek(new Date())
  },
  computed: {
    ...mapGetters(['calendarEvents']),
    calendarTitle() {
      return this.week[0]?.month === this.week[6]?.month ? this.week[0]?.month + ' ' + this.week[0]?.year : this.week[0]?.month + ' - ' + this.week[6]?.month + ' ' + this.week[0]?.year
    },
    filteredEventsList() {
      if (this.calendarEvents) {
        const events = this.calendarEvents.filter(i => {
          return (i.date >= this.week[0]?.date && i.date <= this.week[6]?.date);
        })
        events.map((e, i) => {
          events[i].colClass = 'sm:col-start-' + (e.date.getDay() === 0 ? 7 : e.date.getDay()).toString()
        })
        return events
      }
      return []
    },
  },
  methods: {
    ...mapActions(['deleteEvent']),
    setCalendarWeek(curr) {
      this.week = []
      for (let i = 1; i <= 7; i++) {
        const date = new Date(curr.setDate(curr.getDate() - curr.getDay() + i))
        i < 7 ? date.setHours(1, 0, 0) : date.setHours(23, 0, 0)
        this.week.push({
          date,
          weekDay: this.days[date.getDay()],
          monthDate: date.getDate(),
          month: this.monthNames[date.getMonth()],
          year: date.getFullYear(),
          isTodayDate: date.toDateString() === new Date().toDateString()
        })
      }
    },
    setCalendarNextWeek() {
      const nextWeek = new Date(this.week[0].date.getTime() + 7 * 24 * 60 * 60 * 1000)
      this.week = []
      this.setCalendarWeek(nextWeek)
    },
    setCalendarPrevWeek() {
      const prevWeek = new Date(this.week[0].date.getTime() - 7 * 24 * 60 * 60 * 1000)
      this.week = []
      this.setCalendarWeek(prevWeek)
    },
    eventTime(startTime, endTime) {
      return 'grid-row:' + this.startTime(startTime.mm, startTime.HH) + '/ span ' + this.endTime(startTime, endTime)
    },
    startTime(minutes, hours) {
      return Math.trunc(2 + (Number(minutes) / 5) + (Number(hours) * 12))
    },
    endTime(startTime, endTime) {
      const hours = Number(endTime.HH) - Number(startTime.HH)
      return Math.trunc((Number(endTime.mm) / 5) + (hours * 12))
    },
    handleOpenModal(type) {
      if (type === 'add') this.addEventModal = true
      else this.deleteEventModal = true
    },
    handleCloseModal(type) {
      type === 'add' ? this.addEventModal = false : this.deleteEventModal = false
    },
    handleDeleteEvent(id) {
      this.id = id
      this.handleOpenModal('delete')
    },
    confirmDeleteEvent() {
      this.deleteEvent(this.id)
      this.id = null
      this.deleteEventModal = false
    },
  },

}
</script>