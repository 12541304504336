<template>
  <div class="flex flex-col mb-8 relative">
    <label for="name" class="text-sm mb-2">{{ $t(label) }}</label>
    <Popper
      arrow
      :show="error"
      :class="{
        'z-auto': errorText === 'Please enter in the specified format',
        'z-0': errorText !== 'Please enter in the specified format'
      }"
    >
      <input
        :disabled="isDisabled"
        :type="fieldType"
        id="name"
        v-model="fieldData"
        :placeholder="placeholder ? $t(placeholder): ''"
        class="h-[56px] w-full rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 placeholder:text-[#D9D9DF] z-1"
        :class="{ 'border-[#5A5A5F]': fieldData }"
        @change="handleChange"
      >
      <template #content>
        <div v-if="errorText && errorText === 'Please enter in the specified format'">
          <div class="text-sm max-w-[240px]">
            <p>
              {{$t('auth.Password strength')}}:
              <span
                :class="{'text-green-500': passwordLvl === 'Hard', 'text-yellow-300': passwordLvl === 'Medium',  'text-red-600': passwordLvl === 'Easy' }"
              >
              {{ passwordLvl }}
            </span>
            </p>
            <p class="my-2">{{$t('auth.Password must meet the following requirement')}}</p>
            <div class="text-[10px]">
              <p :class="{'text-green-500': isContain8Characters,  'text-red-600': !isContain8Characters }">
                <span v-if="isContain8Characters">&#10004;</span>
                <span v-else>&#10006;</span>
                {{$t('auth.Contain atleast 8 characters')}}
              </p>
              <p :class="{'text-green-500': isHaveUppercase,  'text-red-600': !isHaveUppercase }">
                <span v-if="isHaveUppercase">&#10004;</span>
                <span v-else>&#10006;</span>
                {{$t('auth.Atleast one uppercase')}} (A-Z)
              </p>
              <p :class="{'text-green-500': isHaveLowercase,  'text-red-600': !isHaveLowercase }">
                <span v-if="isHaveLowercase">&#10004;</span>
                <span v-else>&#10006;</span>
                {{$t('auth.Atleast one lowercase')}} (a-z)
              </p>
              <p :class="{'text-green-500': isContainsNumber,  'text-red-600': !isContainsNumber }">
                <span v-if="isContainsNumber">&#10004;</span>
                <span v-else>&#10006;</span>
                {{$t('auth.Atleast one number')}} (0-9)
              </p>
              <p :class="{'text-green-500': isContainSpecialCharacter,  'text-red-600': !isContainSpecialCharacter }">
                <span v-if="isContainSpecialCharacter">&#10004;</span>
                <span v-else>&#10006;</span>
                {{$t('auth.Atleast one special character')}} (!,@,#,*,$,%)
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="errorText && errorText !== 'Please enter in the specified format'">
          <p  class="text-sm text-red-600">{{$t(`${errorText}`) }}</p>
        </div>
      </template>
    </Popper>
    <EyeIcon
      v-if="password"
      class="w-[24px] h-[24px] text-[#B6B6BB] z-50 absolute cursor-pointer top-[44px] right-[20px]"
      :class="{ 'text-[#5A5A5F]': fieldData }"
      @click="changeType"
    />
  </div>
</template>

<script>
import {EyeIcon} from "@heroicons/vue/20/solid"
import Popper from "vue3-popper";

export default {
  name: "InputControl",
  components: {
    EyeIcon,
    Popper,
  },
  props: {
    data: {
      type: String,
    },
    dataKey: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String
    },
    password: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    errorText: {
      type: String,
    }
  },
  data() {
    return {
      fieldData: '',
      fieldType: 'text',
    }
  },
  mounted() {
    this.fieldType = this.type
    this.fieldData = this.data
  },
  computed: {
    isContain8Characters() {
      return this.fieldData.length > 7
    },
    isHaveUppercase() {
      return /[A-Z]/.test(this.fieldData)
    },
    isHaveLowercase() {
      return /[a-z]/.test(this.fieldData)
    },
    isContainsNumber() {
      return /\d/.test(this.fieldData)
    },
    isContainSpecialCharacter() {
      return /[!@#*$%]+/.test(this.fieldData)
    },
    passwordLvl() {
      let lvl = 0
      if (this.isContain8Characters) lvl += 1
      if (this.isHaveUppercase) lvl += 1
      if (this.isHaveLowercase) lvl += 1
      if (this.isContainsNumber) lvl += 1
      if (this.isContainSpecialCharacter) lvl += 1

      if (lvl === 5) return "Hard"
      if (lvl > 2 && lvl < 5) return "Medium"
      return "Easy"
    }
  },
  methods: {
    changeType() {
      this.fieldType === 'text' ? this.fieldType = 'password' : this.fieldType = 'text';
    },
    handleChange() {
      this.$emit('update', {key: this.dataKey, data: this.type === 'number' ? Number(this.fieldData) : this.fieldData})
    },
  },
}
</script>
<style>
:root {
  --popper-theme-background-color: #fff;
  --popper-theme-background-color-hover: #fff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 8px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>