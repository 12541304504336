<template>
  <div class="min-h-screen flex justify-center items-center">
    <div class="hidden md:flex justify-center items-center w-1/2 min-h-screen p-8 bg-[#E1D2B9] drop-shadow-md">
      <img :src="require('@/assets/hcLogo.svg')" class="w-[85%]"/>
    </div>
    <div class="md:w-1/2 min-h-screen p-4 flex flex-col justify-center items-center font-poppins relative">
      <div class="absolute top-[5%] right-[5%]">
        <LangSwitcherComponent/>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LangSwitcherComponent from "@/components/blocks/header/LangSwitcherComponent.vue";

export default {
  name: "AuthLayoutComponent",
  components: {
    LangSwitcherComponent,
  },
}
</script>