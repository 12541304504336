<template>
  <div class="text-[#5A5A5F]">
    <p class="text-[32px] font-medium mb-3">{{ $t("auth.title") }}</p>
    <form @submit.prevent="onSubmit" class="w-full md:w-[352px]">
      <p class="mb-2 text-sm text-end">*{{ $t("auth.Mandatory Fields") }}</p>
      <InputControl
        type="email"
        label="auth.email label"
        placeholder="auth.email placeholder"
        dataKey="email"
        :error="!!v$.formData.email.$errors"
        :errorText="v$.formData.email.$errors[0]?.$message"
        @update="onFieldChange"
      />

      <InputControl
        type="password"
        label="auth.password label"
        placeholder="auth.password placeholder"
        dataKey="password"
        password
        :error="!!v$.formData.password.$errors"
        :errorText="v$.formData.password.$errors[0]?.$message"
        @update="onFieldChange"
      />

      <router-link
        :to="{ name: routeNames.forgotPassword }"
        class="text-[#9A8053] text-sm"
      >
        {{ $t("auth.forgot password") }}?
      </router-link>

      <Button :disabled="loading" :loading="loading" class="mt-4">{{
        $t("auth.login")
      }}</Button>

      <p class="my-3 text-sm">
        {{ $t("auth.Not a member") }}?
        <router-link
          :to="{ name: 'registration' }"
          class="cursor-pointer text-[#9A8053] ml-1"
        >
          {{ $t("auth.Create account now") }}
        </router-link>
      </p>
    </form>
    <SocialComponent @emittedSocial="registerWithSocial" />
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import SocialComponent from "@/components/auth/SocialComponent";
import Button from "@/components/UI/Button";
import InputControl from "@/components/UI/InputControl";
import {mapActions, mapGetters, mapMutations} from "vuex";
import routeNames from "@/router/routes";

export default {
  name: "LoginFormComponent",
  components: {
    SocialComponent,
    Button,
    InputControl,
  },
  data() {
    return {
			routeNames,
      v$: useVuelidate(),
      formData: {
        email: '',
        password: '',
      },
    }
  },
  validations: {
    formData: {
      email: {
        required: helpers.withMessage('This field is required', required),
        email: helpers.withMessage('This field must be a valid email', email),
      },
      password: {
        required: helpers.withMessage('This field is required', required),
      },
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  mounted() {
    this.SET_LOADING(false)
  },
  methods: {
    ...mapActions(['loginWithEmail', 'loginWithSocial']),
    ...mapMutations(['SET_LOADING',]),
    registerWithSocial(social) {
      this.loginWithSocial(social)
    },
    async onSubmit() {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.SET_LOADING(true)
        await this.loginWithEmail({email: this.formData.email, password: this.formData.password})
      }
    },
    onFieldChange(val) {
      this.formData[val.key] = val.data
    },
  },
}
</script>
