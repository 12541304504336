<template>
  <router-view />
</template>
<script>
export default {
  methods: {
    setLocale() {
      const locale = localStorage.getItem("horseapp-lang");
      this.$i18n.locale = locale ? locale : "En";
    },
  },
  async mounted() {
    this.setLocale();
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
