<template>
  <button
    :disabled="disabled"
    class="w-full text-[18px] rounded py-2 px-2 my-3"
    :class="{ 'bg-gray-400': disabled, 'bg-[#9A8053] text-white':!cancelStyle, 'border border-[#9A8053] text-[#9A8053]': cancelStyle }"
  >
    <half-circle-spinner
      v-if="loading"
      :animation-duration="1000"
      :size="27"
      color="#fff"
      class="mx-auto"
    />
    <span v-else>
      <slot></slot>
    </span>

  </button>
</template>

<script>
import {HalfCircleSpinner} from 'epic-spinners'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Button",
  components: {
    HalfCircleSpinner
  },
  props: {
    disabled: Boolean,
    loading: Boolean,
    cancelStyle: Boolean,
  }
}
</script>