import axios from "axios";
import router from "@/router";
import routeNames from "@/router/routes";
axios.defaults.headers.post["Content-Type"] = "application/json";
// import { useToast } from 'vue-toastification';

const baseURL = process.env.VUE_APP_BASE_URL;
const apiKey = process.env.VUE_APP_API_KEY;

// const toast = useToast();
const api = axios.create({ baseURL });

api.interceptors.request.use(
  function (config) {
    const token =
      JSON.parse(localStorage.getItem("horsecare-userTokens")).token || "";
    config.headers["Accept"] = "*/*";
    config.headers["Access-Control-Allow-Origin"] = "*";
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const tokens = JSON.parse(localStorage.getItem("horsecare-userTokens"));
    const originalRequest = error.config;
    console.log(originalRequest);

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await axios.post(
          `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
          {
            grant_type: "refresh_token",
            refreshToken: JSON.parse(
              localStorage.getItem("horsecare-userTokens")
            ).refreshToken,
          }
        );

        tokens.token = newToken.data.access_token;
        tokens.refresh_token = newToken.data.refreshToken;

        localStorage.setItem("horsecare-userTokens", JSON.stringify(tokens));
        window.location.reload();
      } catch (err) {
        console.log(err);
        localStorage.removeItem("horsecare-userTokens");
        router.push({ name: routeNames.login });
      }
    }
    return error;
  }
);

export default api;
