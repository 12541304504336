<template>
  <div class="w-full h-screen flex justify-center items-center">
    <half-circle-spinner :animation-duration="1000" :size="60" color="#D68E0F" />
  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
export default {
  name: "LoaderComponent",
  components: {
    HalfCircleSpinner,
  },
}
</script>