<template>
  <div class="text-[#5A5A5F]">
    <p class="text-[32px] font-medium mb-3">{{ $t("auth.forgot password") }}</p>
    <form @submit.prevent="onSubmit" class="w-full md:w-[352px]">
      <p class="my-3 text-sm">
        {{ $t("auth.We will send instructions in your registered email") }}
      </p>

      <InputControl
        type="email"
        label="auth.email label"
        placeholder="auth.email placeholder"
        dataKey="email"
        :error="!!v$.formData.email.$errors"
        :errorText="v$.formData.email.$errors[0]?.$message"
        @update="onFieldChange"
      />
      <div class="flex">
        <Button cancelStyle class="mt-4 mr-2" @click="cancel">{{
          $t("auth.Cancel")
        }}</Button>
        <Button :disabled="loading" :loading="loading" class="mt-4 ml-2">{{
          $t("auth.Send")
        }}</Button>
      </div>

      <p class="my-3 text-sm">
        {{ $t("auth.Not a member") }}?
        <router-link
          :to="{ name: routeNames.registration }"
          class="cursor-pointer text-[#9A8053] ml-1"
        >
          {{ $t("auth.Create account now") }}
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
import InputControl from "@/components/UI/InputControl";
import Button from "@/components/UI/Button";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import routeNames from "@/router/routes";

export default {
  name: "ForgotPasswordFormComponent",
  components: {
    InputControl,
    Button,
  },
  data() {
    return {
      routeNames,
      v$: useVuelidate(),
      formData: {
        email: "",
      },
    };
  },
  validations: {
    formData: {
      email: {
        required: helpers.withMessage("This field is required", required),
        email: helpers.withMessage("This field must be a valid email", email),
      },
    },
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    ...mapMutations(["SET_LOADING"]),
    onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.SET_LOADING(true);
        this.forgotPassword(this.formData.email);
      }
    },
    onFieldChange(val) {
      this.formData[val.key] = val.data;
    },
    cancel() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
