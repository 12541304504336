const routeNames = {
  home: "home",
  registration: "registration",
  login: "login",
  forgotPassword: "forgotPassword",
  blockedAccount: "blockedAccount",
  horses: "horses",

  training: "training",
  tracking: "tracking",
  centerOfGravityTracking: "center-of-gravity-tracking",
  comparison: "comparison",
  taskboard: "taskboard",
  errorPage: "404",
};

export default routeNames;
