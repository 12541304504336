<template>
  <div class="w-full mt-4 flex justify-between items-center">
    <div class="flex justify-center items-center">
      <svg
        v-if="isTrackPaused && !isTrackStarted"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-114 h-14 mr-2 cursor-pointer text-[#9A8053]"
        @click="playTrack"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-14 h-14 mr-2 cursor-pointer text-[#9A8053]"
        @click="pauseTrack"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <div
        class="min-w-[80px] text-center text-[#5A5A5F] text-[18px] font-semibold mr-2"
      >
        {{ formattedCurrentTime }}
      </div>
    </div>

    <div class="mx-auto mb-9 md:mb-0 flex justify-between items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-12 h-12 cursor-pointer text-[#9A8053]"
        @click="changeSpeed('-')"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
        />
      </svg>
      <div
        class="w-[40px] flex justify-center items-center text-[#5A5A5F] text-[18px] mt-1"
      >
        X <span class="ml-1">{{ speed }}</span>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-12 h-12 mr-4 cursor-pointer text-[#9A8053]"
        @click="changeSpeed('+')"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "PlayControllerButtonsComponent",
  props: {
    trackingMarkersLength: Number,
    cutValue: Array,
  },
  watch: {
    trackingMarkersLength() {
      this.SET_CURRENT_TRACK_INDEX(0);
    },
  },
  computed: {
    ...mapGetters([
      "speed",
      "currentTrackIndex",
      "isTrackStarted",
      "isTrackPaused",
    ]),
    formattedCurrentTime() {
      if (this.cutValue) {
        const date = this.cutValue[0] * 200 + this.currentTrackIndex * 200;
        return new Date(date).toISOString().slice(11, 19);
      }
      return new Date(this.currentTrackIndex * 200).toISOString().slice(11, 19);
    },
  },
  methods: {
    ...mapMutations([
      "SET_TRACKING_SPEED",
      "SET_CURRENT_TRACK_INDEX",
      "SET_IS_TRACK_STARTED",
      "SET_IS_TRACK_PAUSED",
    ]),
    ...mapActions(["playTrack", "pauseTrack"]),
    changeSpeed(type) {
      this.pauseTrack();
      const speedIncrement = type === "+" ? 1 : -1;
      const speedOptions = [1, 2, 4, 10, 20];
      const currentIndex = speedOptions.indexOf(this.speed);
      const newIndex = currentIndex + speedIncrement;
      if (newIndex >= 0 && newIndex < speedOptions.length) {
        this.SET_TRACKING_SPEED(speedOptions[newIndex]);
      }
      this.playTrack();
    },
    format(value) {
      return new Date(value * 200).toISOString().slice(11, 19);
    },
  },
  beforeUnmount() {
    this.pauseTrack();
  },
};
</script>
