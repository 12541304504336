<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope"
  >
    <!-- Legend with Data -->
    <div class="w-full flex flex-wrap gap-4 mb-4">
      <div
        v-for="chart in legendWithData"
        :key="chart.scaleId"
        class="flex items-center"
      >
        <div
          class="h-3 w-3 mr-2"
          :style="{ backgroundColor: chart.color }"
        ></div>
        <div class="text-xs">{{ chart.title }}: {{ chart.currentValue }}</div>
      </div>
    </div>

    <!-- Speed control & jumps selector -->
    <div class="w-full flex flex-wrap items-center justify-between mb-4">
      <div class="flex items-center">
        <span class="text-[#5A5A5F] text-[18px] font-semibold mr-4">
          {{ $t("tracking.Animation speed") }}
        </span>
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
          }"
          dataKey="animationSpeed"
          :data="animationSpeed"
          :options="animationSpeedOptions.map((i) => i.label)"
          @update="onSpeedChange"
        />
      </div>

      <div class="flex items-center">
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
          }"
          dataKey="jumps"
          :data="selectedJump?.label || null"
          :options="jumpOptions.map((option) => option.label)"
          defaultOption="Select jump"
          @update="selectNewJump"
        />
      </div>
    </div>

    <!-- Time Slider with Start, Current, and End Times -->
    <div class="w-full my-2 flex items-center justify-between">
      <!-- Play Controls -->
      <div class="flex items-center mr-4">
        <button v-if="!isPlaying" @click="playAnimation" class="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-114 h-14 mr-2 cursor-pointer text-[#9A8053]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
            />
          </svg>
        </button>
        <button v-else @click="stopAnimation" class="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-14 h-14 mr-2 cursor-pointer text-[#9A8053]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        <button
          @click="resetAnimation"
          class="p-2 h-fit rounded-md border-2 border-[#9A8053] flex items-center font-semibold text-[#9A8053] mr-2"
        >
          Reset
        </button>
      </div>

      <div class="flex items-center w-full">
        <span>{{ formatTime(rederStart * 1000) }}</span>

        <div class="flex flex-col items-center w-full mx-2 mb-4">
          <span class="text-xs mb-1">{{ formattedTimestamp }}</span>
          <input
            type="range"
            :min="0"
            :max="totalFrames"
            v-model="currentFrame"
            @input="onSliderInput"
            class="w-full"
            ref="sliderInput"
          />
        </div>

        <span>{{ formatTime(rederEnd * 1000) }}</span>
      </div>
    </div>

    <!-- 3D Container -->
    <div
      id="3d-container"
      class="w-full h-full relative"
      :style="{ height: `${containerHeight}px` }"
    >
      <div
        v-if="loading"
        class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75"
      >
        <span>Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { mapGetters, mapMutations } from "vuex";
import { animationSpeedOptions } from "@/components/training/constants";
import SelectControl from "@/components/UI/SelectControl.vue";

let scene, camera, renderer, controls, mixer, clock, model;

export default {
  name: "TrainingSimulator",
  components: { SelectControl },

  props: {
    selectedJump: Object,
    legendWithData: Array,
    cutValue: Array,
    jumpOptions: Array,
  },

  emits: ["selectNewJump"],

  data() {
    return {
      animationSpeedOptions,

      containerHeight: 0,
      animationDuration: 0,
      currentTime: 0,
      rederStart: 0,
      rederEnd: 0,
      currentFrame: 0,
      totalFrames: 0,
      frameRate: 30,
      pausedTime: 0,
      animationSpeed: "1x",
      isPlaying: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["reports", "currentTrackIndex"]),

    formattedTimestamp() {
      const secondsFromStart = this.currentFrame / this.frameRate;
      const totalSeconds = Math.floor(secondsFromStart + this.rederStart);

      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },
  },

  watch: {
    currentFrame(newVal) {
      // if animation ends
      if (newVal === this.totalFrames - 1) {
        this.stopAnimation();

        const newJump = this.jumpOptions.find(
          (i) => i.id === this.selectedJump.id + 1
        );

        if (newJump) {
          const newJumpOption = {
            key: "jumps",
            data: newJump.label,
          };
          this.selectNewJump(newJumpOption);
        }
      }

      const secondsFromStart = newVal / this.frameRate;
      const totalSeconds = Math.floor(secondsFromStart + this.rederStart);

      let newTrackIndex; // need add +1 for going to next second
      if (this.cutValue) {
        newTrackIndex =
          (totalSeconds - Math.floor(this.cutValue[0] / 5)) * 5 + 1;
      } else {
        newTrackIndex = totalSeconds * 5 + 1;
      }

      this.SET_CURRENT_TRACK_INDEX(newTrackIndex);
    },

    // update animation file
    async selectedJump() {
      this.currentFrame = 0;
      this.pausedTime = 0;
      this.stopAnimation();
      await this.loadSelectedJumpData();
      // Update animation speed after loading the new jump
      const defaultSpeed = this.animationSpeedOptions.find(
        (opt) => opt.label === this.animationSpeed
      );
      if (defaultSpeed) {
        this.onSpeedChange({ data: defaultSpeed.label });
      }
    },
  },

  methods: {
    ...mapMutations(["SET_CURRENT_TRACK_INDEX"]),

    async selectNewJump(jump) {
      this.$emit("selectNewJump", jump);
    },

    onSpeedChange({ data }) {
      if (this.action) {
        const newSpeed = this.animationSpeedOptions.find(
          (option) => option.label === data
        );

        if (newSpeed) {
          this.animationSpeed = newSpeed.label;
          this.action.timeScale = Number(newSpeed.speed);
        }
      }
    },

    timeToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    },

    calculateFreeHeight() {
      const viewportHeight = window.innerHeight;
      const offsetTop = this.$el.getBoundingClientRect().top / 2;
      const padding = 50;
      this.containerHeight = viewportHeight - offsetTop - padding;
    },

    formatTime(milliseconds) {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },

    async initScene() {
      const container = document.getElementById("3d-container");
      scene = new THREE.Scene();

      // Camera setup
      camera = new THREE.PerspectiveCamera(
        50,
        container.offsetWidth / container.offsetHeight,
        0.1,
        2000
      );
      camera.position.set(350, 200, 400);
      camera.lookAt(new THREE.Vector3(0, 75, 0));

      // Renderer setup
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      container.appendChild(renderer.domElement);

      // Controls setup
      controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 75, 0);
      controls.minDistance = 200;
      controls.maxDistance = 600;
      controls.minPolarAngle = Math.PI / 6;
      controls.maxPolarAngle = Math.PI / 1.9;
      controls.update();

      clock = new THREE.Clock();

      // Adding lights
      this.addLights();
      this.setupEnvironment();
      this.loadTexturesAndGeometry(); // Add ground texture and helper
    },

    addLights() {
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 1.5);
      hemiLight.position.set(0, 200, 0);
      scene.add(hemiLight);

      const dirLight = new THREE.DirectionalLight(0xffffff, 1);
      dirLight.position.set(75, 75, 75);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 500;
      scene.add(dirLight);
    },

    setupEnvironment() {
      const rgbeLoader = new RGBELoader();
      rgbeLoader.load("/models/sky_2k.hdr", (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.background = texture;
        scene.environment = texture;
      });
    },

    loadTexturesAndGeometry() {
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load("/models/grass.jpg", (texture) => {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(100, 100);

        const planeGeometry = new THREE.PlaneGeometry(10000, 10000);
        const planeMaterial = new THREE.MeshLambertMaterial({ map: texture });

        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        plane.rotation.x = -Math.PI / 2;
        plane.position.y = -8;
        plane.receiveShadow = true;

        scene.add(plane);
      });
    },

    async loadSelectedJumpData() {
      this.loading = true;

      try {
        if (model) {
          scene.remove(model);
          model.traverse((child) => {
            if (child.isMesh) {
              child.geometry.dispose();
              child.material.dispose();
            }
          });
          model = null;
          if (mixer) mixer.stopAllAction();
        }

        const jumpUrl = this.selectedJump.reder_path;

        if (jumpUrl) {
          const dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath("jsm/libs/draco/");
          const loader = new GLTFLoader();
          loader.setDRACOLoader(dracoLoader);

          const gltf = await loader.loadAsync(jumpUrl);
          model = gltf.scene;
          model.scale.set(100, 100, 100);
          model.position.set(0, 0, 0);
          scene.add(model);

          mixer = new THREE.AnimationMixer(model);
          const animation = gltf.animations[0];
          if (animation) {
            this.action = mixer.clipAction(animation);
            this.animationDuration = animation.duration * 1000;
            this.action.paused = true;
          }

          this.rederStart = this.timeToSeconds(this.selectedJump.reder_start);
          this.rederEnd = this.timeToSeconds(this.selectedJump.reder_end);

          // Update totalFrames based on the animation duration
          this.totalFrames = Math.floor(
            (this.animationDuration / 1000) * this.frameRate
          );
        }
      } catch (error) {
        console.error("Failed to load jump data:", error);
      } finally {
        this.loading = false;
      }
    },

    render() {
      if (renderer) renderer.render(scene, camera);
    },

    animate() {
      requestAnimationFrame(this.animate);

      if (mixer && this.isPlaying) {
        // Calculate a fixed delta based on frame rate
        const delta = 1 / (this.frameRate * 2); // Model has 60 FPS by default
        mixer.update(delta);

        // Update currentFrame based on action.time for accurate playback progress
        const newFrame = Math.floor(
          (this.action.time / this.action.getClip().duration) * this.totalFrames
        );
        if (newFrame !== this.currentFrame) {
          this.currentFrame = newFrame;
        }
      }

      this.render();
    },

    playAnimation() {
      if (!this.isPlaying && mixer && this.action) {
        this.action.time = this.pausedTime;
        this.action.paused = false;
        this.isPlaying = true;
        this.action.play();
        clock.start();
      }
    },

    stopAnimation() {
      if (this.isPlaying && mixer && this.action) {
        this.action.paused = true;
        this.isPlaying = false;
        this.pausedTime = this.action.time;
        this.currentFrame = Math.floor(
          (this.pausedTime / this.action.getClip().duration) * this.totalFrames
        );
        clock.stop();
      }
    },

    resetAnimation() {
      if (mixer && this.action) {
        this.onSpeedChange({ data: "1x" });
        this.action.stop();
        this.action.time = 0;
        this.action.paused = true;
        this.currentFrame = 0;
        this.pausedTime = 0;
        this.isPlaying = false;
        camera.position.set(350, 200, 400);
        camera.lookAt(new THREE.Vector3(0, 75, 0));
        controls.target.set(0, 75, 0);
        controls.update();
        clock.stop();
        clock.elapsedTime = 0;

        this.render();
      }
    },

    onSliderInput() {
      if (mixer && this.action) {
        // Calculate the target time based on sliders frame position
        const targetTime =
          (this.currentFrame / this.totalFrames) *
          this.action.getClip().duration;

        // Set the action time and update paused time for correct sync
        this.action.time = targetTime;
        this.pausedTime = targetTime;
        this.action.paused = true;
        this.isPlaying = false;

        this.action.play();
        clock.start();

        mixer.update(0);
        this.render();
      }
    },

    onWindowResize() {
      const container = document.getElementById("3d-container");
      camera.aspect = container.offsetWidth / container.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(container.offsetWidth, container.offsetHeight);
    },

    disposeScene() {
      if (model) {
        model.traverse((child) => {
          if (child.isMesh) {
            child.geometry.dispose();
            if (Array.isArray(child.material)) {
              child.material.forEach((mat) => mat.dispose());
            } else if (child.material) {
              child.material.dispose();
            }
          }
        });
        scene.remove(model);
        model = null;
      }

      if (renderer) {
        renderer.dispose();
        renderer.forceContextLoss();
        renderer.domElement = null;
        renderer = null;
      }

      if (controls) {
        controls.dispose();
        controls = null;
      }

      scene = null;
      camera = null;
      mixer = null;
      clock = null;
    },

    handleKeyDown(event) {
      const { key, shiftKey } = event;

      switch (key) {
        case "ArrowLeft":
          if (this.currentFrame > 0) {
            this.currentFrame = shiftKey
              ? Number(this.currentFrame) - 5
              : Number(this.currentFrame) - 1;
            if (this.currentFrame < 0) this.currentFrame = 0; // Ensure currentFrame doesn't go below 0
            this.onSliderInput();
          }
          break;
        case "ArrowRight":
          if (this.currentFrame < this.totalFrames) {
            this.currentFrame = shiftKey
              ? Number(this.currentFrame) + 5
              : Number(this.currentFrame) + 1;
            if (this.currentFrame > this.totalFrames)
              this.currentFrame = this.totalFrames; // Ensure currentFrame doesn't go under totalFrames
            this.onSliderInput();
          }
          break;
        case " ":
          event.preventDefault();
          if (!this.isPlaying) {
            this.playAnimation();
          } else {
            this.stopAnimation();
          }
          break;
        default:
          break;
      }
    },
  },

  beforeUnmount() {
    this.stopAnimation();
    this.disposeScene();
    window.removeEventListener("resize", this.onWindowResize);
    document.removeEventListener("keydown", this.handleKeyDown);
  },

  async mounted() {
    this.calculateFreeHeight();
    await this.initScene();
    await this.loadSelectedJumpData();
    this.onWindowResize();
    this.animate();
    window.addEventListener("resize", this.onWindowResize);
    document.addEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style scoped>
:deep(#animationSpeed) {
  height: 40px;
  font-size: 14px;
}
#3d-container {
  width: 100%;
  height: 100%;
  min-height: 70vh;
  max-height: 100vh;
}
input[type="range"] {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
