<template>
  <div class="flex justify-center items-center text-[#5A5A5F]">
    <select
      id="lang"
      name="horses"
      class="max-h-[32px] p-1 rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 placeholder:text-[#D9D9DF] text-sm font-semibold"
      v-model="fieldData"
      @change="handleChange"
    >
      <option v-for="option of options" :key="option" :value="option" class="text-[#5A5A5F] font-semibold">{{option}}</option>
    </select>
    <CountryFlag :iso="flag" size="medium"  class="ml-2 w-[32px] h-[32px]"/>
  </div>
</template>

<script>
import CountryFlag from 'vue3-country-flag-icon'
import 'vue3-country-flag-icon/dist/CountryFlag.css'
export default {
  name: "LangSwitcherComponent",
  components: {
    CountryFlag,
  },
  data() {
    return {
      options: ['En', 'Ru', 'Lv'],
      fieldData: 'En',
    }
  },
  mounted() {
    const locale = localStorage.getItem('horseapp-lang')
    this.fieldData = locale ? locale : this.options[0]
  },
  computed: {
    flag() {
      if(this.$i18n.locale === 'En') return 'us'
      else if(this.$i18n.locale === 'Ru') return 'ru'
      else if(this.$i18n.locale === 'Lv') return 'lv'
      else return 'us'
    },
  },
  methods: {
    handleChange() {
      localStorage.setItem('horseapp-lang', this.fieldData)
      this.$i18n.locale = this.fieldData
    },
  },
}
</script>