<template>
  <div class="flex flex-col mb-3 text-[#5A5A5F] w-full">
    <label class="mb-2 text-sm">{{ label }}</label>
    <Datepicker
      id="date"
      class="h-[56px] w-full rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 placeholder:text-[#D9D9DF] text-md font-semibold"
      v-model="fieldData"
    />
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
export default {
  name: "DatePickerControl",
  props: {
    label: String,
    dataKey: String,
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      fieldData: null,
    }
  },
  watch: {
    fieldData() {
      this.handleChange()
    }
  },
  mounted() {
    this.fieldData = new Date()
  },
  methods: {
    handleChange() {
      this.fieldData.setHours(7);
      this.$emit('update', {key: this.dataKey, data: this.fieldData})
    },
  },
}
</script>