<template>
  <div class="flex flex-col mb-3 text-[#5A5A5F] w-full">
    <label for="description" class="mb-2 text-sm">{{ label }}</label>
    <textarea
      id="description"
      name="description"
      class="w-full rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 placeholder:text-[#D9D9DF] text-md font-semibold"
      v-model="fieldData"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: "TextareaControl",
  props: {
    label: String,
    dataKey: String,
    data: String,
  },
  data() {
    return {
      fieldData: '',
    }
  },
  mounted() {
    this.fieldData = this.data
  },
  methods: {
    handleChange() {
      this.$emit('update', {key: this.dataKey, data: this.fieldData})
    },
  },
}
</script>