<template>
  <li v-if="notifications.length" class="relative">
    <Popper arrow hover class="z-10 overflow-visible">
      <button>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[24px] h-[24px] mr-4 mt-1 text-[#5A5A5F] cursor-pointer" @click="showHover = !showHover">
          <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
        </svg>
      </button>
      <template #content>
        <div class="min-h-full ">
          <p class="text-[#9A8053] text-[20px] font-semibold text-center mx-4">{{ $t('share.Notifications')}}</p>
          <div
            v-for="(notification, index) of notifications" :key="index"
            class="min-w-[320px] bg-[#F2F2F8] border border-[#9A8053] p-4 m-4 rounded-lg"
          >
            <p class="text-[#5A5A5F] text-[20px] font-semibold text-center mb-4">{{$t('share.Share horse promotion')}}</p>
            <div class="text-[#5A5A5F] text-[16px] font-normal">
              <span>{{notification.sender.firstName}} {{notification.sender.lastName}} </span> (<span class="text-[#9A8053]">{{notification.sender.login}}</span>) {{$t('share.shared horse with you')}}:
            </div>
            <div class="w-full mx-auto mt-2 mb-4 p-4 bg-white shadow-lg rounded-lg flex justify-start items-center">
              <img :src="setAvatarUrl(notification.horse.avatarUrl)" alt="horse" class="w-16 h-16 rounded-full"/>
              <div class="ml-2 font-semibold text-[#5A5A5F] ">
                <div class="text-[18px]">{{notification.horse.name}}</div>
                <div class="text-[12px] text-[#A3A3A3]">{{$t('horse.' + notification.horse.gender)}},  {{notification.horse.age}} {{notification.horse.age > 1 ? $t('horse.yrs') : $t('horse.year')}},  {{notification.horse.weight}} {{$t('horse.kg')}}</div>
              </div>
            </div>
            <div class="flex justify-around items-center text-[18px] font-semibold">
              <button class="text-green-600 mr-2 flex justify-center items-center" @click="handleAcceptNotification(notification.id)">
              <span class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
              </span>
                {{ $t('share.Accept')}}
              </button>
              <button class="text-red-600 ml-2 flex justify-center items-center" @click="handleDeclineNotification(notification.id)">
              <span class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </span>
                {{ $t('share.Decline')}}
              </button>
            </div>
          </div>
        </div>

      </template>
    </Popper>
    <div class="absolute -top-1 right-2 w-[15px] h-[15px] bg-red-500 rounded-full flex justify-center items-center text-[10px] text-white font-semibold">{{notifications.length}}</div>
  </li>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import Popper from "vue3-popper";
export default {
  name: "NotificationsComponent",
  components: {Popper},
  data() {
    return {
      showHover: false,
    }
  },
  computed: {
    ...mapGetters(['sharingInList']),
    notifications() {
      const filteredSharingInList = this.sharingInList.filter(i => i.status === 'REQUESTED')
      return filteredSharingInList ? filteredSharingInList : null
    },
  },
  methods: {
    ...mapActions(['acceptSharingInRequest','getHorsesList', 'getSharingInList', 'deleteSharingInRequest']),
    setAvatarUrl(url) {
      if(url && url.includes('https://')) return url
      else return require('@/assets/horse.png')
    },
    async handleAcceptNotification(id) {
      await this.acceptSharingInRequest(id)
      await this.getHorsesList(this.$route.params.page)
      await this.getSharingInList()
    },
    async handleDeclineNotification(id) {
      await this.deleteSharingInRequest(id)
      await this.getSharingInList()
    },
  },
}
</script>
