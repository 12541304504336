import { useToast } from "vue-toastification";
import api from "@/axiosConfig";

const toast = useToast();

const state = {
  skewnessHistory: null,
  energyCoastHistory: null,
  rangeHistory: null,
};

const getters = {
  skewnessHistory: (state) => state.skewnessHistory,
  energyCoastHistory: (state) => state.energyCoastHistory,
  rangeHistory: (state) => state.rangeHistory,
};

const mutations = {
  SET_SKEWNESS_HISTORY(state, data) {
    state.skewnessHistory = data;
  },
  SET_ENERGY_COAST_HISTORY(state, data) {
    state.energyCoastHistory = data;
  },
  SET_RANGE_HISTORY(state, data) {
    state.rangeHistory = data;
  },
};

const actions = {
  async getSkewnessHistory({ commit }, params) {
    return api
      .get(`horses/${params.horseId}/training-reports`, {
        params: {
          "from-date": params.fromDate,
          "to-date": params.toDate,
          "result-type": "STIFFNESS_METRICS",
          "result-node": "STIFFNESS_POWER_DISTRIBUTION",
        },
      })
      .then((res) => {
        const content = res.data.trainings.content;
        let result = [];

        content.map((i) => {
          result.push({
            trainingDate: new Date(i.startTime),
            trainingData: i.results[0].rowResult,
          });
        });
        commit("SET_SKEWNESS_HISTORY", result);
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async getEnergyCoastHistory({ commit }, params) {
    return api
      .get(`horses/${params.horseId}/training-reports`, {
        params: {
          "from-date": params.fromDate,
          "to-date": params.toDate,
          "result-type": "STIFFNESS_METRICS",
          "result-node": "EXPENDITURE_ENERGY_METRICS",
        },
      })
      .then((res) => {
        const content = res.data.trainings.content;
        let result = [];

        content.map((i) => {
          result.push({
            trainingDate: new Date(i.startTime),
            trainingData: i.results[0].rowResult,
          });
        });

        commit("SET_ENERGY_COAST_HISTORY", result);
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
