<template>
  <HomeLayoutComponent>
    <LoaderComponent v-if="loading" />
    <TrainingsMainComponent v-else />
  </HomeLayoutComponent>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TrainingsMainComponent from "@/components/training/index/TrainingsMainComponent.vue";
import LoaderComponent from "@/components/LoaderComponent";
import HomeLayoutComponent from "@/components/layouts/HomeLayoutComponent";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";

export default {
  name: "TrainingView",
  components: {
    HomeLayoutComponent,
    LoaderComponent,
    TrainingsMainComponent,
  },
  async mounted() {
    this.SET_LOADING(true);
    if (!this.horsesList) await this.getHorsesList(this.$route.params.page);
    this.setCurrentHorse();
  },
  computed: {
    ...mapGetters(["loading", "chartsData", "horsesList"]),
  },
  methods: {
    ...mapActions(["getHorsesList"]),
    ...mapMutations(["SET_LOADING", "SET_HORSE_TRACKING_CARD"]),
    setCurrentHorse() {
      const currentHorse = this.horsesList.find(
        (h) => h.id === Number(this.$route.params.id)
      );
      if (!currentHorse) {
        return showErrorAndGoToHomePage("Horse not found");
      }
      this.SET_HORSE_TRACKING_CARD(currentHorse);
    },
  },
};
</script>
