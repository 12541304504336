<template>
  <HomeLayoutComponent>
    <LoaderComponent v-if="loading" />
    <TrackingMainComponent v-else />
  </HomeLayoutComponent>
</template>
<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import HomeLayoutComponent from "@/components/layouts/HomeLayoutComponent.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TrackingMainComponent from "@/components/training/tracking/trackingComponents/TrackingMainComponent.vue";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";

export default {
  name: "TrackingView",
  components: {
    HomeLayoutComponent,
    LoaderComponent,
    TrackingMainComponent,
  },
  computed: {
    ...mapGetters(["loading", "horsesList", "horseTrackingCard"]),
  },
  methods: {
    ...mapActions(["getHorsesList"]),
    ...mapMutations(["SET_LOADING", "SET_HORSE_TRACKING_CARD"]),
    setCurrentHorse() {
      const currentHorse = this.horsesList.find(
        (h) => h.id === Number(this.$route.params.id)
      );
      if (!currentHorse) {
        return showErrorAndGoToHomePage("Horse not found");
      }
      this.SET_HORSE_TRACKING_CARD(currentHorse);
    },
  },
  async mounted() {
    this.SET_LOADING(true);
    if (!this.horsesList) await this.getHorsesList(this.$route.params.page);
    this.setCurrentHorse();
  },
};
</script>
