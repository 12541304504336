import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import i18n from "./translations";
import "vue-toastification/dist/index.css";
import "./index.css";
import VCalendar from "v-calendar";
import "v-calendar/style.css";

createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(i18n)
  .use(VCalendar, {})
  .mount("#app");
