<template>
  <div class="max-w-[560px] p-4 flex flex-col justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="currentColor"
      class="w-[96px] h-[96px] text-red-600"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
      />
    </svg>
    <p class="text-[32px] text-red-600 text-center my-4">Account blocked</p>
    <p class="text-center">
      Sorry, your account has been blocked as you have entered wrong password
      for 3 times
    </p>
    <p class="my-4 text-center">
      Please try to relogin after
      <span class="font-semibold">{{ time }}</span> minutes
    </p>
  </div>
</template>

<script>
import routeNames from "@/router/routes";

export default {
  name: "BlockedAccountComponent",
  data() {
    return {
			routeNames,
      minutes: 2,
      seconds: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.setInterval();
  },
  computed: {
    time() {
      let minutes = "0" + this.minutes;
      let seconds = this.seconds === 0 ? "00" : this.seconds;
      return minutes + ":" + seconds;
    },
  },
  methods: {
    setInterval() {
      this.intervalId = setInterval(() => this.setTime(), 1000);
    },
    setTime() {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          clearInterval(this.intervalId);
          this.$router.push({ name: this.routeNames.login });
        } else {
          this.minutes = this.minutes - 1;
          this.seconds = 59;
        }
      }
      this.seconds = this.seconds - 1;
    },
  },
};
</script>
